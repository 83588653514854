'use client';
import { useState, useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';

import styles from './AmbassadorRanking.module.scss';

export const AmbassadorRanking = (props) => {
  const [ranking, setRanking] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const formatRank = (rank) => {
    let result = '';
    switch (rank) {
      case 1:
        result = '🥇';
        break;
      case 2:
        result = '🥈';
        break;
      case 3:
        result = '🥉';
        break;
    }
    return `${result}${rank}位`;
  };

  useEffect(() => {
    if (!props.data) return;
    setRanking(props.data.ranking ?? []);

    // Dateオブジェクトに変換
    const startDate = new Date(props.data.target_start_date);
    const endDate = new Date(props.data.target_end_date);
    // フォーマット: "YYYY.MM.dd~MM.dd"
    const formattedStartDate = `${startDate.getFullYear()}.${
      startDate.getMonth() + 1
    }.${startDate.getDate()}`;
    const formattedEndDate = `${endDate.getMonth() + 1}.${endDate.getDate()}`;
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  }, [props]);

  return (
    <div className={styles.container}>
      <div className={styles.period}>{`期間：${startDate}~${endDate}`}</div>
      <div className={styles.table}>
        <TableContainer maxWidth={'100%'}>
          <Table>
            <Thead>
              <Tr>
                <Th>月額順位</Th>
                <Th>ユーザー</Th>
                <Th isNumeric>ゲストCV数</Th>
                <Th isNumeric>売上金額</Th>
              </Tr>
            </Thead>
            <Tbody>
              {ranking.length == 0 && (
                <Tr>
                  <Td>対象となるデータが存在しません</Td>
                </Tr>
              )}
              {ranking.length != 0 &&
                ranking.map((v) => {
                  return (
                    <Tr key={v.id}>
                      <Td>{formatRank(v.amount_ranking)}</Td>
                      <Td>{v.email}</Td>
                      <Td>{v.total_cv}</Td>
                      <Td>{v.total_amount}</Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
