import { ReportScreen } from '@/frontend/components/pages/report/Report';

export const metadata = {
  title: 'レポート | Letters',
  description: 'Lettersのレポート画面です。',
};

const Report = () => {
  return <ReportScreen />;
};

export default Report;
