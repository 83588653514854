import { ViewScreen } from '@/frontend/components/pages/ambassadors/View';

export const metadata = {
  title: 'アンバサダー詳細 | Letters',
  description: 'Lettersのアンバサダー詳細画面です。',
};

const View = () => {
  return <ViewScreen />;
};

export default View;
