import styles from './HelpText.module.scss';
import { Tooltip } from '@chakra-ui/react';
import iconHelp from '/icons/icon-help.svg';

export const HelpText = (props) => {
  return (
    <Tooltip label={props.label} placement='auto' hasArrow>
      <span className={styles.helpText}>
        <img src={iconHelp} alt={props.text} />
        {props.text}
      </span>
    </Tooltip>
  );
};
