"use client";

import { Spacer } from "../../elements/spacer/Spacer";
import { Button } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import iconBeginner from '/icons/icon-beginner.svg';
import iconStep1 from '/icons/icon-tutorial-step1.svg';
import iconStep2 from '/icons/icon-tutorial-step2.svg';
import iconStep3 from '/icons/icon-tutorial-step3.svg';
import styles from "./Step1.module.scss";

export const Step1 = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <img src={iconBeginner} alt="チュートリアル" />
      </div>
      <Spacer size="8px" />
      <div className={styles.title}>かんたんスタート設定</div>
      <Spacer size="32px" />
      <p className={styles.text}>
        リファラルマーケティングアプリへようこそ！
        <br />
        3STEPでかんたんにはじめてみましょう！
        <br />
        最低限の設定で運用開始いただけます。
      </p>
      <Spacer size="24px" />
      <div className={styles.steps}>
        <div className={styles.step}>
          <div className={styles.number}>
            <img src={iconStep1} alt="STEP1" />
          </div>
          <div className={styles.text}>
            インセンティブ設定
            <br />
            forアンバサダー
          </div>
        </div>
        <div className={styles.step}>
          <div className={styles.number}>
            <img src={iconStep2} alt="STEP2" />
          </div>
          <div className={styles.text}>
            インセンティブ設定
            <br />
            forゲスト
          </div>
        </div>
        <div className={styles.step}>
          <div className={styles.number}>
            <img src={iconStep3} alt="STEP3" />
          </div>
          <div className={styles.text}>運用スタート</div>
        </div>
      </div>
      <Spacer size="40px" />
      <Button
        className={styles.button}
        size="lg"
        variant="solid"
        colorScheme="blue"
        rightIcon={<ChevronRightIcon />}
        onClick={props.onNext}
      >
        かんたんスタート設定をはじめる
      </Button>
      <Spacer size="24px" />
      <a href="#" className={styles.skip} onClick={props.onSkip}>
        自分で設定する
      </a>
    </div>
  );
};
