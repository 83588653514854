import { OrderScreen } from '@/frontend/components/pages/order/Order';

export const metadata = {
  title: '売上注文一覧 | Letters',
  description: 'Lettersの売上注文一覧画面です。',
};

const Order = () => {
  return <OrderScreen />;
};

export default Order;
