import { useEffect, useState } from 'react';
import { Alert, AlertIcon, Stack } from '@chakra-ui/react';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';

// attributeを日本語化
const validationAttributes = (field) => {
  let attribute = '';
  switch (field) {
    case 'account': // アカウント設定はモデルのエラーメッセージが返却されるため空文字を出力
      attribute = '';
      break;
    case 'range': // 範囲はメッセージのみとするため空文字を出力
      attribute = '';
      break;
    case 'code': // エラーメッセージが返却されるため空文字を出力
      attribute = '';
      break;
    case 'code_list':
      attribute = 'コード';
      break;

    case 'name':
      attribute = '氏名';
      break;
    case 'email':
      attribute = 'メールアドレス';
      break;
    case 'password':
      attribute = 'パスワード';
      break;
    case 'ambassador_coupon_value':
      attribute = 'インセンティブ内容の値';
      break;
    case 'ambassador_coupon_min_price':
      attribute = 'インセンティブ発生最小金額';
      break;
    case 'ambassador_coupon_incentive_grace':
      attribute = 'インセンティブ発生猶予期間';
      break;
    case 'guest_coupon_value':
      attribute = 'インセンティブ内容の値';
      break;
    case 'guest_coupon_code':
      attribute = 'クーポンコード';
      break;
    default:
      attribute = field;
      break;
  }
  return attribute;
};

const ValidationErrors = ({ errors }) => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const newMessages = [];
    for (const field in errors) {
      if (errors.hasOwnProperty(field)) {
        errors[field].forEach((message) => {
          const jaField = validationAttributes(field);
          newMessages.push(
            <Alert key={`${field}-${message}`} status='error'>
              <AlertIcon />
              {jaField} {message}
            </Alert>
          );
        });
      }
    }
    setMessages(newMessages);
  }, [errors]); // errorsが変更されるたびに実行

  if (messages.length === 0) return null; // エラーメッセージが空なら表示しない

  return (
    <>
      <Stack spacing={3}>{messages}</Stack>
      <Spacer size='16px' />
    </>
  );
};

export default ValidationErrors;
