'use client';

import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { Card } from '@/frontend/components/elements/cards/Card';
import { Editor } from '@/frontend/components/elements/editor/Editor';
import { HelpText } from '@/frontend/components/elements/helpText/HelpText';
import {
  Button,
  Stack,
  Input,
  Select,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Badge,
  useToast,
  Switch,
} from '@chakra-ui/react';
import { mdiThumbUpOutline } from '@mdi/js';
import { MdMailOutline } from 'react-icons/md';
import Icon from '@mdi/react';
import { useState, useEffect } from 'react';
import ValidationErrors from '@/frontend/components/elements/validation/ValidationErrors';

import styles from './RecruitMail.module.scss';
import {
  getRecruitMailDocument as getRecruitMailDocumentApi,
  postUpdateRecruitMailSetting as postUpdateRecruitMailSettingApi,
  postTestSendRecruitMail as postTestSendRecruitMailApi,
} from '@/frontend/src/backendApi';

export const RecruitMailScreen = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [body, setBody] = useState('');
  const [title, setTitle] = useState('');
  const [mailType, setMailType] = useState(2); // TODO: 不要
  const [sendAfterDate, setSendAfterDate] = useState(1);
  const [sendAfterHour, setSendAfterHour] = useState(0);
  const [recruitMailSetting, setRecruitMailSetting] = useState(false);
  const toast = useToast();
  const [errors, setErrors] = useState({});

  // リクルートメール情報取得
  const getRecruitMailDocument = async () => {
    const response = await getRecruitMailDocumentApi();
    console.log('recruit_mail_document');
    console.log(response.data.data);

    setBody(response.data.data.mail_settings_form.body ?? '');
    setTitle(response.data.data.mail_settings_form.title ?? '');
    setMailType(response.data.data.mail_settings_form.mail_type ?? 2);
    setSendAfterDate(
      response.data.data.mail_settings_form.send_after_date ?? 1
    );
    setSendAfterHour(
      response.data.data.mail_settings_form.send_after_hour ?? 0
    );
    setRecruitMailSetting(response.data.data.is_recruit ?? false);
  };

  const save = async () => {
    // TODO: フロントバリデーション
    const params = {
      title: title,
      body: body,
      send_after_date: sendAfterDate,
      send_after_hour: sendAfterHour,
      mail_type: mailType,
      act: recruitMailSetting,
    };

    try {
      const response = await postUpdateRecruitMailSettingApi(params);
      console.log(response);
      setErrors({});
    } catch (e) {
      console.error(e);
      setErrors(e.response.data.error ?? {});

      toast({
        title: '保存に失敗しました',
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }

    // 後処理
    window.scrollTo({ top: 0, behavior: 'smooth' });
    toast({
      title: '保存しました',
      status: 'success',
      duration: '3000',
      isClosable: true,
    });
    (async () => await getRecruitMailDocument())();
  };

  const sendTestMail = async () => {
    // TODO: フロントバリデーション
    const params = {
      title: title,
      body: body,
      send_after_date: sendAfterDate,
      send_after_hour: sendAfterHour,
      mail_type: mailType,
    };

    try {
      const response = await postTestSendRecruitMailApi(params);
      console.log(response);
      setErrors({});
    } catch (e) {
      console.error(e);
      setErrors(e.response.data.error ?? {});

      toast({
        title: '送信に失敗しました',
        description: `${e}`,
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }

    // 後処理
    window.scrollTo({ top: 0, behavior: 'smooth' });
    toast({
      title: '送信しました',
      status: 'success',
      duration: '3000',
      isClosable: true,
    });
  };

  // fetch
  useEffect(() => {
    getRecruitMailDocument();
  }, []);

  return (
    <>
      <h2 className={styles.title}>ドキュメント管理</h2>
      <Spacer size='16px' />
      <h3 className={styles.subtitle}>招待メール設定</h3>
      <Spacer size='8px' />
      <p className={styles.description}>
        ここではアンバサダーへの招待メールの見え方を設定します。
      </p>
      <Spacer size='24px' />

      <Card title='招待メール情報' forAmbassador={true}>
        <Spacer size='24px' />
        <div className={styles.info}>
          <TableContainer>
            <Table>
              <Tbody>
                <Tr>
                  <Th>招待メール設定</Th>
                  <Td>{recruitMailSetting ? '有効' : '無効'}</Td>
                </Tr>
                <Tr>
                  <Th>送信日時設定</Th>
                  <Td>{`購入${sendAfterDate}日後の${sendAfterHour}時に参加依頼メールを送信する`}</Td>
                </Tr>
                <Tr>
                  <Th>メールタイトル</Th>
                  <Td>{title}</Td>
                </Tr>
                <Tr>
                  <Th>メール本文</Th>
                  <Td>
                    <div dangerouslySetInnerHTML={{ __html: body }}></div>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      </Card>

      <Spacer size='32px' />

      <Card
        title='編集'
        id='edit'
        className={`${styles.edit} ${isEdit ? styles.edit__active : ''}`}
      >
        <Spacer size='16px' />
        <ValidationErrors errors={errors} />

        <div className={styles.edit__form}>
          <form>
            <div className={styles.edit__title}>
              プログラム参加依頼メール設定
              <HelpText
                label={
                  <div>
                    商品購入者に紹介プログラム招待メールを自動送信することができます。
                    <br />
                    (商品購入ページにて、「☑️ニュースとオファーをメールで受け取る」にチェックをしている購入者のみに送付される仕様となります)
                  </div>
                }
              />
            </div>
            <Spacer size='8px' />
            <Switch
              size='lg'
              isChecked={recruitMailSetting}
              onChange={() => setRecruitMailSetting(!recruitMailSetting)}
            />
            {recruitMailSetting ? (
              <div className={styles.program__content__activated}>有効</div>
            ) : (
              <div className={styles.program__content__deactivated}>無効</div>
            )}

            <Spacer size='16px' />
            <label className={styles.edit__form__label}>
              送信日時設定
              <Badge
                colorScheme='red'
                marginLeft='8px'
                style={{ background: 'rgba(229, 62, 62, 1)', color: '#ffffff' }}
              >
                必須
              </Badge>
            </label>
            <Spacer size='16px' />
            <span>
              購入
              <Input
                size='md'
                variant='outline'
                width='60px'
                placeholder='1'
                margin={'0 8px'}
                w={'180px'}
                value={sendAfterDate}
                onChange={(e) => setSendAfterDate(e.target.value)}
              />
              日後の
              <Input
                size='md'
                variant='outline'
                width='60px'
                placeholder='18'
                margin={'0 8px'}
                w={'180px'}
                value={sendAfterHour}
                onChange={(e) => setSendAfterHour(e.target.value)}
              />
              時に参加依頼メールを送信する
            </span>
            <Spacer size='8px' />
            <Stack
              direction={'row'}
              spacing={'8px'}
              align='center'
              className={styles.edit__advice}
            >
              <Icon
                path={mdiThumbUpOutline}
                size={'16px'}
                color='rgba(128, 90, 213, 1)'
              />
              <span>設定アドバイス：到着直後がおすすめです</span>
            </Stack>
            <Spacer size='32px' />

            <label className={styles.edit__form__label}>
              メールタイトル
              <Badge
                colorScheme='red'
                marginLeft='8px'
                style={{ background: 'rgba(229, 62, 62, 1)', color: '#ffffff' }}
              >
                必須
              </Badge>
            </label>
            <Spacer size='8px' />
            <Input
              size='md'
              variant='outline'
              placeholder='「xxx」アンバサダー・プログラムへのご招待'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <Spacer size='32px' />

            {false && (
              <>
                <label className={styles.edit__form__label}>メールタイプ</label>
                <Spacer size='8px' />
                <Select size='md' w='320px'>
                  <option value='text'>テキストメール</option>
                  <option value='html'>HTMLメール</option>
                </Select>
                <Spacer size='32px' />
              </>
            )}

            <label className={styles.edit__form__label}>メール本文</label>
            <Spacer size='8px' />
            <Editor value={body} setValue={setBody} />
            <Spacer size='32px' />

            <Stack direction='row' align='center' justify='center'>
              <Button
                size='sm'
                variant='outline'
                colorScheme='blue'
                leftIcon={<MdMailOutline />}
                onClick={sendTestMail}
              >
                テストメールを自分に送信
              </Button>
            </Stack>
          </form>
        </div>
      </Card>

      <div className={styles.action}>
        {!isEdit && (
          <Button
            size='lg'
            variant='solid'
            colorScheme={'blue'}
            className={styles.action__edit}
            width='450px'
            height='48px'
            onClick={() => {
              setIsEdit(!isEdit);
              const target = document.getElementById('edit');
              const rect = target.getBoundingClientRect().top;
              const offset = window.pageYOffset;
              const gap = 80;
              const targetY = rect + offset - gap;
              window.scrollTo({ top: targetY, behavior: 'smooth' });
            }}
          >
            編集する
          </Button>
        )}
        {isEdit && (
          <Stack
            direction='row'
            spacing='8px'
            width='100%'
            align='center'
            justify='center'
          >
            <Button
              size='lg'
              variant='outline'
              colorScheme={'blue'}
              className={styles.action__cancel}
              width='240px'
              height='48px'
              onClick={() => setIsEdit(!isEdit)}
            >
              キャンセル
            </Button>
            <Button
              size='lg'
              variant='solid'
              colorScheme={'blue'}
              className={styles.action__save}
              width='240px'
              height='48px'
              onClick={save}
            >
              保存する
            </Button>
          </Stack>
        )}
      </div>
    </>
  );
};
