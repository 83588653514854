import { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { Card } from '@/frontend/components/elements/cards/Card';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  HStack,
  useToast,
} from '@chakra-ui/react';
import { InputGroup, Input } from '@chakra-ui/react';
import ValidationErrors from '@/frontend/components/elements/validation/ValidationErrors';

import styles from './Report.module.scss';
import {
  getReport as getReportApi,
  getReportDownload as getReportDownloadApi,
} from '@/frontend/src/backendApi';

export const ReportScreen = () => {
  const toast = useToast();
  const [summaryObject, setSummaryObject] = useState({});
  const [total, setTotal] = useState({});
  const [form, setForm] = useState({
    start_date: '',
    end_date: '',
  });
  const [errors, setErrors] = useState({});

  // レポート取得
  const getReport = async () => {
    const response = await getReportApi();
    console.log('get_report');
    console.log(response.data.data);

    setForm(
      response.data.data.form ?? {
        start_date: '',
        end_date: '',
      }
    );
    setSummaryObject(response.data.data.report.summary_list ?? []);
    setTotal(response.data.data.report.total_list ?? []);
  };

  const getSalesPer = (v) => {
    return Number(v.site_induction_num) != 0
      ? Math.round(
          (((parseFloat(v.letters_buy_count) /
            parseFloat(v.site_induction_num)) *
            100.0) /
            10) *
            10
        )
      : 0;
  };

  // 日付形式変換
  function formatDate(date, sep = '/') {
    const yyyy = date.getFullYear();
    const mm = ('00' + (date.getMonth() + 1)).slice(-2);
    const dd = ('00' + date.getDate()).slice(-2);

    return `${yyyy}${sep}${mm}${sep}${dd}`;
  }

  const search = async (e) => {
    e.preventDefault();

    const params = {
      start_date: form.start_date,
      end_date: form.end_date,
    };

    try {
      const response = await getReportApi(params);
      console.log(response);
      setErrors({});
      setSummaryObject(response.data.data.report.summary_list ?? []);
      setTotal(response.data.data.report.total_list ?? []);
    } catch (e) {
      console.error(e);
      setErrors(e.response.data.error ?? {});
      toast({
        title: '検索に失敗しました',
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }
  };

  const download = async (e) => {
    e.preventDefault();

    const params = {
      start_date: form.start_date,
      end_date: form.end_date,
    };

    try {
      const response = await getReportDownloadApi(params);
      console.log(response);
      setErrors({});
      const blob = new Blob([response.data], { type: 'text/csv' });

      // sv-SEロケールはYYYY-MM-DD形式の日付文字列を戻す
      saveAs(blob, `レポート_${new Date().toLocaleDateString('sv-SE')}.csv`);
    } catch (e) {
      console.error(e);
      setErrors({ range: ['100日以内で検索してください'] });

      toast({
        title: 'CSVダウンロードに失敗しました',
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }
  };

  // fetch
  useEffect(() => {
    getReport();
  }, []);

  return (
    <>
      <h2 className={styles.title}>レポート</h2>

      <Spacer size='16px' />

      <p className={styles.description}>ここでは期間を設定して検索できます。</p>

      <Spacer size='24px' />
      <ValidationErrors errors={errors} />

      <Card>
        <form>
          <label className={styles.label}>期間(最大100日)</label>
          <Spacer size='8px' />
          <HStack spacing='8px'>
            <InputGroup size='md' width='214px'>
              <Input
                type='date'
                placeholder='2024/01/01'
                name='start_date'
                className={styles.input}
                value={form.start_date}
                onChange={(e) => {
                  setForm({
                    ...form,
                    ...{ start_date: e.target.value },
                  });
                }}
                required
              />
            </InputGroup>
            <span>〜</span>
            <InputGroup size='md' width='214px'>
              <Input
                type='date'
                placeholder='2024/01/31'
                name='end_date'
                className={styles.input}
                value={form.end_date}
                onChange={(e) => {
                  setForm({
                    ...form,
                    ...{ end_date: e.target.value },
                  });
                }}
                required
              />
            </InputGroup>
          </HStack>
          <Spacer size='16px' />
          <Button
            type='submit'
            size={'md'}
            variant={'solid'}
            colorScheme={'blue'}
            onClick={search}
          >
            検索する
          </Button>
        </form>
      </Card>

      <Spacer size='32px' />

      <Card
        title='検索結果'
        action={
          <Button
            size='sm'
            variant='outline'
            colorScheme='blue'
            onClick={download}
          >
            CSV出力
          </Button>
        }
      >
        <div className={styles.table}>
          <TableContainer maxWidth={'100%'}>
            <Table>
              <Thead>
                <Tr>
                  <Th>日付</Th>
                  <Th>リクルート数</Th>
                  <Th>リクルートPV数</Th>
                  <Th>新規アンバサダー数</Th>
                  <Th>紹介ページPV数</Th>
                  <Th>サイト誘導数</Th>
                  <Th>Letters経由の購入数（ゲストCV）</Th>
                  <Th>Letters売上金額</Th>
                  <Th>Letters購入率</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Object.keys(summaryObject).map((key) => {
                  return (
                    <Tr key={key}>
                      <Td>{formatDate(new Date(key))}</Td>
                      <Td>{summaryObject[key].recruit_num ?? 0}</Td>
                      <Td>{summaryObject[key].recruit_pv ?? 0}</Td>
                      <Td>{summaryObject[key].new_ambassador_num ?? 0}</Td>
                      <Td>{summaryObject[key].invitation_pv ?? 0}</Td>
                      <Td>{summaryObject[key].site_induction_num ?? 0}</Td>
                      <Td>{summaryObject[key].letters_buy_count ?? 0}</Td>
                      <Td>
                        ￥
                        {Number(
                          summaryObject[key].letters_amount
                        ).toLocaleString() ?? 0}
                      </Td>
                      <Td>
                        {summaryObject[key].site_induction_num
                          ? getSalesPer(summaryObject[key])
                          : 0}
                        %
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
              <Tfoot backgroundColor={'rgba(235, 248, 255, 1)'}>
                <Tr>
                  <Td>合計</Td>
                  <Td>{total.recruit_num ?? 0}</Td>
                  <Td>{total.recruit_pv ?? 0}</Td>
                  <Td>{total.new_ambassador_num ?? 0}</Td>
                  <Td>{total.invitation_pv ?? 0}</Td>
                  <Td>{total.site_induction_num ?? 0}</Td>
                  <Td>{total.letters_buy_count ?? 0}</Td>
                  <Td>
                    ￥{Number(total.letters_amount).toLocaleString() ?? 0}
                  </Td>
                  <Td>{total.site_induction_num ? getSalesPer(total) : 0}%</Td>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>
        </div>
      </Card>
    </>
  );
};
