import { AmbassadorScreen } from '@/frontend/components/pages/document/Ambassador';

export const metadata = {
  title: '紹介ページ編集（アンバサダー向け） - ドキュメント管理 | Letters',
  description: 'アンバサダー向けの紹介ページを管理できます。',
};

const Ambassador = () => {
  return <AmbassadorScreen />;
};

export default Ambassador;
