import { JoinMailScreen } from '@/frontend/components/pages/document/JoinMail';

export const metadata = {
  title: '登録完了メール設定 - ドキュメント管理 | Letters',
  description: 'ここではアンバサダーへの登録完了メールの見え方を設定します',
};

const JoinMail = () => {
  return <JoinMailScreen />;
};

export default JoinMail;
