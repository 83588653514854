import { useState, useEffect } from 'react';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { Card } from '@/frontend/components/elements/cards/Card';
import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Stack,
  useToast,
  Input,
} from '@chakra-ui/react';
import ValidationErrors from '@/frontend/components/elements/validation/ValidationErrors';

import styles from './TestUser.module.scss';
import {
  getTestUserList as getTestUserListApi,
  postAddTestUser as postAddTestUserApi,
  postEditTestUser as postEditTestUserApi,
  deleteTestUser as deleteTestUserApi,
} from '@/frontend/src/backendApi';

export const TestUserScreen = (props) => {
  const [testUserList, setTestUserList] = useState([]);
  const [editUser, setEditUser] = useState('');
  const [userInfo, setUserInfo] = useState({
    mail_address: '',
  });
  const [errors, setErrors] = useState({});

  const toast = useToast();

  // 登録テストユーザー一覧取得
  const getTestUserList = async () => {
    const response = await getTestUserListApi();
    console.log('test_user_list');
    console.log(response.data.data);
    setTestUserList(
      response.data.data.test_user_list.length != 0
        ? response.data.data.test_user_list.data
        : []
    );
  };

  const setUpUserInfo = (type) => {
    switch (type) {
      case 'add':
        setEditUser('add');
        break;
    }
  };

  const save = async () => {
    // TODO: フロントバリデーション
    const params = {
      user: {
        email: userInfo.mail_address,
      },
    };

    try {
      const response = await postAddTestUserApi(params);
      console.log(response);
      setErrors({});
    } catch (e) {
      console.error(e);
      setErrors(e.response.data.error ?? {});

      toast({
        title: '保存に失敗しました',
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }

    // 後処理
    window.scrollTo({ top: 0, behavior: 'smooth' });
    toast({
      title: '保存しました',
      status: 'success',
      duration: '3000',
      isClosable: true,
    });
    setEditUser('');
    setUserInfo({
      mail_address: '',
    });
    (async () => await getTestUserList())();
  };

  const updateUser = async (params) => {
    try {
      const response = await postEditTestUserApi(params);
      console.log(response);
      setErrors({});
    } catch (e) {
      console.error(e);
      setErrors(e.response.data.error ?? {});

      toast({
        title: '更新に失敗しました',
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }

    // 後処理
    window.scrollTo({ top: 0, behavior: 'smooth' });
    toast({
      title: '更新しました',
      status: 'success',
      duration: '3000',
      isClosable: true,
    });
    setEditUser('');
    setUserInfo({
      mail_address: '',
    });
    (async () => await getTestUserList())();
  };

  const deleteUser = async (email) => {
    if (
      !window.confirm('ユーザーを削除します。元に戻せませんがよろしいですか？')
    ) {
      return;
    }

    const params = { email: email };

    try {
      const response = await deleteTestUserApi(params);
      console.log(response);
    } catch (e) {
      console.error(e);
      toast({
        title: '削除に失敗しました',
        description: `${e}`,
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }

    // 後処理
    window.scrollTo({ top: 0, behavior: 'smooth' });
    toast({
      title: '削除しました',
      status: 'success',
      duration: '3000',
      isClosable: true,
    });
    setEditUser('');
    setUserInfo({
      mail_address: '',
    });
    (async () => await getTestUserList())();
  };

  // fetch
  useEffect(() => {
    getTestUserList();
  }, []);

  return (
    <>
      <h2 className={styles.title}>設定</h2>
      <Spacer size='24px' />
      <h3 className={styles.subtitle}>テストユーザー管理</h3>
      <Spacer size='8px' />
      <p className={styles.description}>
        ここではLettersアプリのテスト購入用アカウントが設定できます。
        <br />
        <span>
          ※有効になっているテストユーザーが購入したCVに関しては請求に含まれません。
        </span>
      </p>
      <Spacer size='24px' />

      <Card>
        <nav className={styles.tab}>
          <ul className={styles.tab__items}>
            {props.role != 3 && (
              // 一般非表示
              <li className={styles.tab__item}>
                <a href='/setting/account/'>アカウント情報管理</a>
              </li>
            )}
            <li className={styles.tab__item}>
              <a href='/setting/user'>ユーザー管理</a>
            </li>
            <li className={`${styles.tab__item} ${styles.tab__item__active}`}>
              <a href='/setting/test_user'>テストユーザー管理</a>
            </li>
          </ul>
        </nav>

        <Spacer size='24px' />

        <div className={styles.section}>
          <h4 className={styles.section__title}>テストユーザー情報</h4>
          <Spacer size='16px' />

          {editUser !== '' && (
            <>
              <ValidationErrors errors={errors} />
              <div className={styles.section__item}>
                <label name={'mail_address'}>メールアドレス</label>
                <Spacer size='8px' />
                <Input
                  name={'mail_address'}
                  placeholder={''}
                  htmlSize={10}
                  width={700}
                  value={userInfo.mail_address ?? ''}
                  onChange={(e) =>
                    setUserInfo({
                      ...userInfo,
                      ...{ mail_address: e.target.value },
                    })
                  }
                />
              </div>
              <Spacer size='24px' />
            </>
          )}

          {editUser !== '' && (
            <>
              <Stack
                direction='row'
                spacing='8px'
                width='100%'
                align='center'
                justify='center'
              >
                <Button
                  size='lg'
                  variant='outline'
                  colorScheme={'blue'}
                  className={styles.action__cancel}
                  width='240px'
                  height='48px'
                  onClick={() => {
                    setEditUser('');
                  }}
                >
                  キャンセル
                </Button>
                <Button
                  colorScheme='green'
                  size={'lg'}
                  width={240}
                  className={styles.button}
                  onClick={save}
                >
                  保存する
                </Button>
              </Stack>
              <Spacer size='48px' />
            </>
          )}

          {editUser === '' && (
            <>
              <Button
                size={'md'}
                variant={'solid'}
                colorScheme={'blue'}
                onClick={() => {
                  setUpUserInfo('add');
                }}
              >
                新規追加
              </Button>
            </>
          )}

          <Spacer size='16px' />

          <div className={styles.table}>
            <TableContainer maxWidth={'100%'}>
              <Table>
                <Thead>
                  <Tr>
                    <Th>メールアドレス</Th>
                    <Th width={140}>状態</Th>
                    <Th width={100}>操作</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {testUserList.map((user) => {
                    return (
                      <Tr key={user.test_user_id}>
                        <Td>{user.email}</Td>
                        <Td>{user.status == 1 ? '有効' : '無効'}</Td>
                        {props.role != 3 && (
                          <Td>
                            <Stack direction={'row'} spacing={'4px'}>
                              <Button
                                size={'xs'}
                                variant={'solid'}
                                colorScheme={'blue'}
                                className={
                                  user.status == 1
                                    ? styles.button__toggle_off
                                    : styles.button__toggle_on
                                }
                                onClick={() => {
                                  updateUser({
                                    email: user.email,
                                    status: user.status == 1 ? 2 : 1,
                                  });
                                }}
                              >
                                {user.status != 1 ? '有効' : '無効'}
                              </Button>
                              <Button
                                size={'xs'}
                                variant={'outline'}
                                colorScheme={'red'}
                                onClick={() => {
                                  deleteUser(user.email);
                                }}
                              >
                                削除
                              </Button>
                            </Stack>
                          </Td>
                        )}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Card>
    </>
  );
};
