import { useState, useEffect } from 'react';
import './App.css';
import styles from '@/frontend/components/layouts/layout/Layout.module.scss';

// common parts
import { Header } from '@/frontend/components/layouts/header/Header';
import { Menu } from '@/frontend/components/layouts/menu/Menu';

// page
//// home
import Home from './pages/Home/page.jsx';
//// setting
import Account from './pages/Setting/account/page.jsx';
import TestUser from './pages/Setting/test-user/page.jsx';
import User from './pages/Setting/user/page.jsx';
//// incentive
import AmbIncentive from './pages/Incentive/ambassador/page.jsx';
import GuestIncentive from './pages/Incentive/guest/page.jsx';
//// report
import Report from './pages/Report/page.jsx';
//// order
import Order from './pages/Order/page.jsx';
//// document
import AmbDocument from './pages/Document/ambassador/page.jsx';
import GuestDocument from './pages/Document/guest/page.jsx';
import RecruitMailDocument from './pages/Document/recruit-mail/page.jsx';
import JoinMailDocument from './pages/Document/join-mail/page.jsx';
//// ambassadors
import Ambassadors from './pages/Ambassadors/page.jsx';
import View from './pages/Ambassadors/[id]/page.jsx';
import Block from './pages/Ambassador/block/page.jsx';

// api
import {
  getLoginInfo as getLoginInfoApi,
  getSettingLoginInfo as getSettingLoginInfoApi,
} from './backendApi';

// 親
function App() {
  const root = document.getElementById('root');
  const accessFrom = root.dataset.accessFrom;
  const subAccessFrom = root.dataset.subAccessFrom;
  const data = root.dataset.backendData
    ? JSON.parse(root.dataset.backendData)
    : {};

  const [companyInfo, setCompanyInfo] = useState({});
  const [currentRole, setCurrentRole] = useState(3);
  const [currentPlan, setCurrentPlan] = useState(1);

  // ログイン情報取得
  const getSettingLoginInfo = async () => {
    const response = await getSettingLoginInfoApi();
    console.log('login_info');
    console.log(response.data.data);
    setCurrentRole(response.data.data.current_role ?? 3);
    setCurrentPlan(response.data.data.current_plan ?? 0);
  };

  // fetch
  useEffect(() => {
    const getLoginInfo = async () => {
      const response = await getLoginInfoApi();
      console.log(response.data.data);
      setCompanyInfo({ ...response.data.data });
    };
    getLoginInfo();
    getSettingLoginInfo();
  }, []);

  const routing = () => {
    console.log('-------------------------');
    console.log(accessFrom);
    console.log(subAccessFrom);
    console.log(companyInfo);
    console.log(currentPlan);
    console.log('-------------------------');

    // Letters管理者以外 かつ 旧プランの場合強制的にHOMEへ
    if (currentRole != 1 && currentPlan == 0) {
      return <Home />;
    }

    switch (accessFrom) {
      case 'home':
        return <Home />;
      case 'company':
        return (
          <div>company</div> // TODO: 管理者ページ
        );
      case 'setting':
        if (subAccessFrom === 'user') return <User role={currentRole} />;
        else if (subAccessFrom === 'account')
          return <Account role={currentRole} />;
        else if (subAccessFrom === 'test_user')
          return <TestUser role={currentRole} />;
        else return <Account />;
      case 'incentive':
        if (subAccessFrom === 'ambassador') return <AmbIncentive />;
        else if (subAccessFrom === 'guest') return <GuestIncentive />;
        else return <AmbIncentive />;
      case 'report':
        return <Report />;
      case 'order':
        return <Order />;
      case 'ambassador':
        if (subAccessFrom === 'index') return <Ambassadors />;
        else if (subAccessFrom === 'view') return <View />;
        else if (subAccessFrom === 'block') return <Block />;
        else if (subAccessFrom === 'mypage') return <Block />; // TODO:
        else return <Ambassadors />;
      case 'document':
        if (subAccessFrom === 'ambassador')
          return <AmbDocument role={currentRole} />;
        else if (subAccessFrom === 'guest') return <GuestDocument />;
        if (subAccessFrom === 'recruit_mail') return <RecruitMailDocument />;
        else if (subAccessFrom === 'join_mail') return <JoinMailDocument />;
        else return <AmbDocument />;
      default: // 404ページ
        return <div>ページが存在しません</div>;
    }
  };

  // TODO: 不要なもの削除
  console.log('-----------');
  console.log(accessFrom);
  console.log(data);
  console.log('-----------');

  return (
    <>
      <div className={styles.wrapper}>
        <Header logined={true} company={companyInfo} />
        <div className={styles.container}>
          <Menu url={companyInfo.url} role={currentRole} />
          <main>{routing()}</main>
        </div>
      </div>
    </>
  );
}

export default App;
