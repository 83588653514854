import { GuestScreen } from '@/frontend/components/pages/incentive/Guest';

export const metadata = {
  title: 'インセンティブ管理 - ゲスト | Letters',
  description: 'ゲストのインセンティブを管理できます。',
};

const Guest = () => {
  return <GuestScreen />;
};

export default Guest;
