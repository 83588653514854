'use client';

import { useState, useEffect } from 'react';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { Card } from '@/frontend/components/elements/cards/Card';
import { HelpText } from '@/frontend/components/elements/helpText/HelpText';
import { CustomEditor } from '@/frontend/components/elements/editor/CustomEditor';
import { ImageLibrary } from '@/frontend/components/elements/imageLibrary/ImageLibrary';
import { Button, Stack, Input, Select, useToast } from '@chakra-ui/react';
import { ChevronUpIcon } from '@chakra-ui/icons';
import ValidationErrors from '@/frontend/components/elements/validation/ValidationErrors';

import styles from './Guest.module.scss';
import {
  getGuestDocument as getGuestDocumentApi,
  postGuestDocument as postGuestDocumentApi,
  postGeneratePreviewHtml as postGeneratePreviewHtmlApi,
} from '@/frontend/src/backendApi';

export const GuestScreen = () => {
  const toast = useToast();
  const [previewDeviceType, setPreviewDeviceType] = useState('mobile');
  const [editDeviceType, setEditDeviceType] = useState('mobile');
  const [isEdit, setIsEdit] = useState(false);

  const [html, setHtml] = useState('');
  const [page_type, setPageType] = useState(1);
  const [logoImagePath, setLogoImagePath] = useState('');
  const [mainImagePath, setMainImagePath] = useState('');
  const [text1, setText1] = useState('');
  const [brandHelp, setBrandHelp] = useState('');
  const [text2, setText2] = useState('');
  const [warningText, setWarningText] = useState('');
  const [errors, setErrors] = useState({});

  // const [input_elements, setInputElement] = useState([]);
  // const [incentiveInfo, setIncentiveInfo] = useState([]);

  // ゲストページ情報取得
  const getGuestDocument = async () => {
    const response = await getGuestDocumentApi();
    console.log('guest_document');
    console.log(response.data.data);

    postGeneratePreviewHtmlApi({
      layout: 'guest',
      html: response.data.data.html,
    }).then((response) => {
      setHtml(response.data);
    });

    setPageType(response.data.data.page_type ?? 1);

    const inputs = response.data.data.input_elements ?? [];
    // setInputElement(inputs);

    // 入力値セット
    const logoImgObj = inputs.filter((item) => item.id === 'logoimg')[0];
    setLogoImagePath(logoImgObj ? logoImgObj['value'] : '');
    const mainImgObj = inputs.filter((item) => item.id === 'mainimg')[0];
    setMainImagePath(mainImgObj ? mainImgObj['value'] : '');
    const text1Obj = inputs.filter((item) => item.id === 'up1txt')[0];
    setText1(text1Obj ? text1Obj['value'] : '');
    const brandHelpObj = inputs.filter((item) => item.id === 'brand_help')[0];
    setBrandHelp(brandHelpObj ? brandHelpObj['value'] : '');
    const text2Obj = inputs.filter((item) => item.id === 'up2txt')[0];
    setText2(text2Obj ? text2Obj['value'] : '');
    const warningTextObj = inputs.filter((item) => item.id === 'warningtxt')[0];
    setWarningText(warningTextObj ? warningTextObj['value'] : '');
  };

  const changeHtml = (id, v) => {
    if (html === '') {
      return;
    }

    // HTML文字列を一時的にDOM要素に変換
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    switch (id) {
      case 'logoimg':
        {
          const element = tempDiv.querySelector('[data-id="logoimg"]');
          const img = element.querySelector('img');
          img.src = v;
        }
        break;
      case 'mainimg':
        {
          const element = tempDiv.querySelector('[data-id="mainimg"]');
          const img = element.querySelector('img');
          img.src = v;
        }
        break;
      case 'up1txt':
        {
          const element = tempDiv.querySelector('[data-id="up1txt"]');
          if (!element) {
            break;
          }
          element.innerHTML = v;
        }
        break;
      case 'brand_help':
        {
          const element = tempDiv.querySelector('[data-id="brand_help"]');
          if (!element) {
            break;
          }
          element.innerHTML = v;
        }
        break;
      case 'up2txt':
        {
          const element = tempDiv.querySelector('[data-id="up2txt"]');
          if (!element) {
            break;
          }
          element.innerHTML = v;
        }
        break;
      case 'warning_txt':
        {
          const element = tempDiv.querySelector('[data-id="warningtxt"]');
          if (!element) {
            break;
          }
          element.innerHTML = v;
        }
        break;
    }
    setHtml(tempDiv.innerHTML);
  };

  const save = async () => {
    // TODO: フロントバリデーション
    const params = {
      page_params: {
        'img:logoimg': logoImagePath,
        'img:mainimg': mainImagePath,
        'text:up1txt': text1,
        'text:brand_help': brandHelp,
        'text:up2txt': text2,
        'text:warningtxt': warningText,
      },
    };
    console.log(params);

    try {
      const response = await postGuestDocumentApi(params);
      console.log(response);
      setErrors({});
    } catch (e) {
      console.error(e);
      setErrors(e.response.data.error ?? {});

      toast({
        title: '保存に失敗しました',
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }

    // 後処理
    window.scrollTo({ top: 0, behavior: 'smooth' });
    toast({
      title: '保存しました',
      status: 'success',
      duration: '3000',
      isClosable: true,
    });
    (async () => await getGuestDocument())();
  };

  // fetch
  useEffect(() => {
    getGuestDocument();
  }, []);

  useEffect(() => {
    changeHtml('logoimg', logoImagePath);
  }, [logoImagePath]);

  useEffect(() => {
    changeHtml('mainimg', mainImagePath);
  }, [mainImagePath]);

  return (
    <>
      <h2 className={styles.title}>ドキュメント管理</h2>
      <Spacer size='16px' />
      <h3 className={styles.subtitle}>紹介ページ編集</h3>
      <Spacer size='8px' />
      <p className={styles.description}>
        ここでは紹介ページ（LP）の見え方を管理します。画像を設定することもできます。
      </p>
      <Spacer size='24px' />

      <Card title='紹介ページ編集' forGuest={true}>
        <Spacer size='16px' />
        <nav className={styles.nav}>
          <ul>
            <li
              className={previewDeviceType === 'mobile' ? styles.active : ''}
              onClick={() => setPreviewDeviceType('mobile')}
            >
              モバイル
            </li>
            <li
              className={previewDeviceType === 'pc' ? styles.active : ''}
              onClick={() => setPreviewDeviceType('pc')}
            >
              PC
            </li>
          </ul>
        </nav>

        <Spacer size='16px' />

        <div className={styles.preview}>
          <nav className={styles.tab}>
            <ul className={styles.tab__items}>
              <li className={styles.tab__item}>
                <a href='/document/ambassador/'>アンバサダー</a>
              </li>
              <li className={`${styles.tab__item} ${styles.tab__item__active}`}>
                <a href='/document/guest'>ゲスト</a>
              </li>
            </ul>
          </nav>

          <Spacer size='24px' />

          <div className={styles.preview__container}>
            <div
              className={
                previewDeviceType === 'mobile'
                  ? styles.preview__mobile
                  : styles.preview__desktop
              }
            >
              <iframe srcDoc={html} className={styles.preview__iframe} />
            </div>
          </div>
        </div>

        <Spacer size='24px' />

        <p className={styles.note}>
          ※最下部にアンバサダー参加用のリンクが表示されます。
        </p>
      </Card>

      <Spacer size='32px' />

      <Card
        title='編集'
        id='edit'
        className={`${styles.edit} ${isEdit ? styles.edit__active : ''}`}
      >
        <Spacer size='16px' />
        {false && (
          <>
            <nav className={styles.nav}>
              <ul>
                <li
                  className={editDeviceType === 'mobile' ? styles.active : ''}
                  onClick={() => setEditDeviceType('mobile')}
                >
                  モバイル
                </li>
                <li
                  className={editDeviceType === 'pc' ? styles.active : ''}
                  onClick={() => setEditDeviceType('pc')}
                >
                  PC
                </li>
              </ul>
            </nav>
            <Spacer size='16px' />
          </>
        )}

        <Button
          size='xs'
          variant='outline'
          colorScheme='blue'
          rightIcon={<ChevronUpIcon />}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          現在の内容を確認する
        </Button>

        <Spacer size='16px' />
        <ValidationErrors errors={errors} />

        <Stack direction='row' spacing='32px' width='100%' align='start'>
          <div className={styles.edit__preview}>
            <div
              className={
                previewDeviceType === 'mobile'
                  ? styles.edit__preview__mobile
                  : styles.edit__preview__desktop
              }
            >
              <iframe srcDoc={html} className={styles.edit__preview__iframe} />
            </div>

            <Spacer size='10px' />

            <div className={styles.edit__preview__caution}>
              【クーポンコードについて】<br />
              {`{{{GUEST_CODE}}}`}という部分にインセンティブ管理でゲストのクーポンコードに反映したコードが表示されます。
            </div>

            <Spacer size='8px' />

            <p className={styles.edit__preview__note}>
              ※最下部に遷移先（ショップ）のリンクが表示されます。
            </p>
          </div>
          <div className={styles.edit__form}>
            <form>
              {false && (
                <>
                  <label className={styles.edit__form__label}>
                    インセンティブ
                  </label>
                  <Spacer size='8px' />
                  <Select size='md' w='320px'>
                    <option value='with'>インセンティブあり</option>
                    <option value='without'>インセンティブなし</option>
                  </Select>
                  <Spacer size='32px' />
                </>
              )}

              {false && (
                <>
                  <label className={styles.edit__form__label}>タイトル</label>
                  <Spacer size='8px' />
                  <Input
                    size='md'
                    variant='outline'
                    placeholder='友人を紹介してクーポンを手に入れよう！'
                    value={''}
                    onChange={() => {}}
                  />
                  <Spacer size='32px' />
                </>
              )}

              <label className={styles.edit__form__label}>ロゴイメージ</label>
              <Spacer size='8px' />
              <ImageLibrary
                parentImagePath={logoImagePath}
                setParentImagePath={setLogoImagePath}
              />
              <Spacer size='32px' />

              <label className={styles.edit__form__label}>
                メインビジュアル
              </label>
              <Spacer size='8px' />
              <ImageLibrary
                parentImagePath={mainImagePath}
                setParentImagePath={setMainImagePath}
              />
              <Spacer size='32px' />

              <Stack
                direction='row'
                spacing='8px'
                align='center'
                justify='start'
              >
                <label className={styles.edit__form__label}>テキスト1</label>
                <HelpText label='テキストを入力してください' />
              </Stack>
              <Spacer size='8px' />
              <CustomEditor
                value={text1}
                onChange={(v) => {
                  setText1(v);
                  changeHtml('up1txt', v);
                }}
              />

              <Spacer size='16px' />
              <Stack
                direction='row'
                spacing='8px'
                align='center'
                justify='start'
              >
                <label className={styles.edit__form__label}>ブランド説明</label>
                <HelpText label='テキストを入力してください' />
              </Stack>
              <Spacer size='8px' />
              <CustomEditor
                value={brandHelp}
                onChange={(v) => {
                  setBrandHelp(v);
                  changeHtml('brand_help', v);
                }}
              />

              <Spacer size='16px' />
              <Stack
                direction='row'
                spacing='8px'
                align='center'
                justify='start'
              >
                <label className={styles.edit__form__label}>テキスト2</label>
                <HelpText label='テキストを入力してください' />
              </Stack>
              <Spacer size='8px' />
              <CustomEditor
                value={text2}
                onChange={(v) => {
                  setText2(v);
                  changeHtml('up2txt', v);
                }}
              />

              <Spacer size='16px' />
              <Stack
                direction='row'
                spacing='8px'
                align='center'
                justify='start'
              >
                <label className={styles.edit__form__label}>注意事項</label>
                <HelpText label='テキストを入力してください' />
              </Stack>
              <Spacer size='8px' />
              <CustomEditor
                value={warningText}
                onChange={(v) => {
                  setWarningText(v);
                  changeHtml('warning_txt', v);
                }}
              />
            </form>
          </div>
        </Stack>
      </Card>

      <div className={styles.action}>
        {!isEdit && (
          <Button
            size='lg'
            variant='solid'
            colorScheme={'blue'}
            className={styles.action__edit}
            width='450px'
            height='48px'
            onClick={() => {
              setIsEdit(!isEdit);
              const target = document.getElementById('edit');
              const rect = target.getBoundingClientRect().top;
              const offset = window.pageYOffset;
              const gap = 80;
              const targetY = rect + offset - gap;
              window.scrollTo({ top: targetY, behavior: 'smooth' });
            }}
          >
            編集する
          </Button>
        )}
        {isEdit && (
          <Stack
            direction='row'
            spacing='8px'
            width='100%'
            align='center'
            justify='center'
          >
            <Button
              size='lg'
              variant='outline'
              colorScheme={'blue'}
              className={styles.action__cancel}
              width='240px'
              height='48px'
              onClick={() => setIsEdit(!isEdit)}
            >
              キャンセル
            </Button>
            <Button
              size='lg'
              variant='solid'
              colorScheme={'blue'}
              className={styles.action__save}
              width='240px'
              height='48px'
              onClick={save}
            >
              保存する
            </Button>
          </Stack>
        )}
      </div>
    </>
  );
};
