import { useState, useEffect } from 'react';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { Card } from '@/frontend/components/elements/cards/Card';
import { Input, Stack, Button, Select, Text, useToast } from '@chakra-ui/react';

import styles from './Account.module.scss';
import {
  getSettingCompany as getSettingCompanyApi,
  getSettingBrandInfo as getSettingBrandInfoApi,
  getSettingLoginInfo as getSettingLoginInfoApi,
  postAccountSetting as postAccountSettingApi,
} from '@/frontend/src/backendApi';
import ValidationErrors from '@/frontend/components/elements/validation/ValidationErrors';

export const AccountScreen = () => {
  const [company, setCompany] = useState({});
  const [genre, setGenre] = useState([]);
  const [brand, setBrand] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [isReadOnly, setReadOnly] = useState(true); // TODO: 編集ボタン押下でfalse
  const [isChangedPassword, setIsChangedPassword] = useState(false); // TODO: パスワードが変更されたらtrue
  const [errors, setErrors] = useState({});

  const toast = useToast();

  // 企業情報取得
  const getSettingCompany = async () => {
    const response = await getSettingCompanyApi();
    console.log('setting_company');
    console.log(response.data.data);
    setCompany(response.data.data.company ?? {});
    setGenre(response.data.data.m_genre ?? []);
  };
  // ブランド情報取得
  const getSettingBrand = async () => {
    const response = await getSettingBrandInfoApi();
    console.log('setting_brand');
    console.log(response.data.data);
    setBrand(response.data.data.brand ?? {});
  };
  // ログイン情報取得
  const getSettingLoginInfo = async () => {
    const response = await getSettingLoginInfoApi();
    console.log('login_info');
    console.log(response.data.data);
    // setUserInfo(response.data.data.current_user);
  };

  const changeEdit = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    console.log(111);
    setReadOnly(!isReadOnly);
  };

  const save = async () => {
    // TODO: フロントバリデーション
    const params = {
      company_info: company,
      brand_info: brand,
      // user_info: userInfo,
    };
    try {
      const response = await postAccountSettingApi(params);
      console.log(response);
      setErrors({});
    } catch (e) {
      console.error(e);
      setErrors(e.response.data.error ?? {});

      toast({
        title: '保存に失敗しました',
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }

    // 後処理
    window.scrollTo({ top: 0, behavior: 'smooth' });
    toast({
      title: '保存しました',
      status: 'success',
      duration: '3000',
      isClosable: true,
    });
    setReadOnly(true);
  };

  // fetch
  useEffect(() => {
    getSettingCompany();
    getSettingBrand();
    getSettingLoginInfo();
  }, []);

  return (
    <>
      <h2 className={styles.title}>設定</h2>
      <Spacer size='24px' />
      <h3 className={styles.subtitle}>アカウント管理</h3>
      <Spacer size='8px' />
      <p className={styles.description}>
        ここではアカウント登録情報の確認・編集ができます。
      </p>
      <Spacer size='24px' />

      <Card>
        <nav className={styles.tab}>
          <ul className={styles.tab__items}>
            <li className={`${styles.tab__item} ${styles.tab__item__active}`}>
              <a href='/setting/account/'>アカウント情報管理</a>
            </li>
            <li className={styles.tab__item}>
              <a href='/setting/user'>ユーザー管理</a>
            </li>
            <li className={styles.tab__item}>
              <a href='/setting/test_user'>テストユーザー管理</a>
            </li>
          </ul>
        </nav>

        <Spacer size='24px' />

        <div className={styles.section}>
          <h4 className={styles.section__title}>企業情報</h4>
          <Spacer size='24px' />
          <ValidationErrors errors={errors} />

          <div className={styles.section__item}>
            <label name={'company_name'}>企業名称</label>
            <Spacer size='8px' />
            <Input
              name={'company_name'}
              placeholder={''}
              htmlSize={10}
              width={700}
              isDisabled={isReadOnly}
              value={company.name ?? ''}
              onChange={(e) =>
                setCompany({ ...company, ...{ name: e.target.value } })
              }
            />
            <Spacer size='16px' />

            <label name={'company_name_kana'}>企業名称カナ</label>
            <Spacer size='8px' />
            <Input
              name={'company_name_kana'}
              placeholder={''}
              htmlSize={10}
              width={700}
              isDisabled={isReadOnly}
              value={company.name_kana ?? ''}
              onChange={(e) =>
                setCompany({ ...company, ...{ name_kana: e.target.value } })
              }
            />
            <Spacer size='16px' />

            <label name={'representative_name'}>代表者名</label>
            <Spacer size='8px' />
            <Input
              name={'representative_name'}
              placeholder={''}
              htmlSize={10}
              width={400}
              isDisabled={isReadOnly}
              value={company.head_name ?? ''}
              onChange={(e) =>
                setCompany({ ...company, ...{ head_name: e.target.value } })
              }
            />
            <Spacer size='16px' />

            <label name={'product_type'}>プロダクトジャンル区分</label>
            <Spacer size='8px' />
            <Select
              size='md'
              isDisabled={isReadOnly}
              defaultChecked={company.m_genre_id}
              value={company.m_genre_id ?? 1}
              onChange={(e) =>
                setCompany({ ...company, ...{ m_genre_id: e.target.value } })
              }
              width={700}
            >
              {genre.map((v) => {
                return (
                  <option key={v.id} value={v.id}>
                    {v.label}
                  </option>
                );
              })}
            </Select>
            <Spacer size='16px' />

            <label name={'address'}>本社所在地</label>
            <Spacer size='8px' />
            <Input
              name={'address'}
              placeholder={''}
              htmlSize={10}
              width={700}
              isDisabled={isReadOnly}
              value={company.address ?? ''}
              onChange={(e) =>
                setCompany({ ...company, ...{ address: e.target.value } })
              }
            />
            <Spacer size='16px' />

            <label name={'company_tel'}>電話番号</label>
            <Spacer size='8px' />
            <Input
              name={'tel'}
              placeholder={''}
              htmlSize={10}
              width={200}
              isDisabled={isReadOnly}
              value={company.tel ?? ''}
              onChange={(e) =>
                setCompany({ ...company, ...{ tel: e.target.value } })
              }
            />
            <Spacer size='16px' />

            <label name={'company_url'}>企業HP URL</label>
            <Spacer size='8px' />
            <Input
              name={'company_url'}
              placeholder={''}
              htmlSize={10}
              width={700}
              isDisabled={isReadOnly}
              value={company.url ?? ''}
              onChange={(e) =>
                setCompany({ ...company, ...{ url: e.target.value } })
              }
            />
          </div>

          <Spacer size='40px' />

          <div className={styles.section}>
            <h4 className={styles.section__title}>担当者情報</h4>
            <Spacer size='24px' />

            <label name={'user_name'}>担当者名</label>
            <Spacer size='8px' />
            <Input
              name={'user_name'}
              placeholder={''}
              htmlSize={10}
              width={400}
              isDisabled={isReadOnly}
              value={company.officer_name ?? ''}
              onChange={(e) =>
                setCompany({ ...company, ...{ officer_name: e.target.value } })
              }
            />
            <Spacer size='16px' />

            <label name={'user_department'}>所属部署</label>
            <Spacer size='8px' />
            <Input
              name={'user_department'}
              placeholder={''}
              htmlSize={10}
              width={700}
              isDisabled={isReadOnly}
              value={company.officer_division ?? ''}
              onChange={(e) =>
                setCompany({
                  ...company,
                  ...{ officer_division: e.target.value },
                })
              }
            />
            <Spacer size='16px' />

            <label name={'user_email'}>担当者メールアドレス</label>
            <Spacer size='8px' />
            <Input
              name={'user_email'}
              placeholder={''}
              htmlSize={10}
              width={700}
              isDisabled={isReadOnly}
              value={company.officer_email ?? ''}
              onChange={(e) =>
                setCompany({
                  ...company,
                  ...{ officer_email: e.target.value },
                })
              }
            />
            <Spacer size='16px' />

            <label name={'user_tel'}>担当者電話番号</label>
            <Spacer size='8px' />
            <Input
              name={'user_tel'}
              placeholder={''}
              htmlSize={10}
              width={200}
              isDisabled={isReadOnly}
              value={company.officer_tel ?? ''}
              onChange={(e) =>
                setCompany({
                  ...company,
                  ...{ officer_tel: e.target.value },
                })
              }
            />
          </div>

          <Spacer size='40px' />

          <div className={styles.section}>
            <h4 className={styles.section__title}>ブランド情報</h4>
            <Spacer size='24px' />

            <label name={'brand_name'}>ブランド名</label>
            <Spacer size='8px' />
            <Input
              name={'brand_name'}
              placeholder={''}
              htmlSize={10}
              width={700}
              isDisabled={isReadOnly}
              value={brand.label ?? ''}
              onChange={(e) =>
                setBrand({
                  ...brand,
                  ...{ label: e.target.value },
                })
              }
            />
            <Spacer size='16px' />

            <label name={'brand_name_alphabet'}>
              ブランド名（半角英数小文字）
            </label>
            <Spacer size='8px' />
            <Input
              name={'brand_name_alphabet'}
              placeholder={''}
              htmlSize={10}
              width={700}
              isDisabled={isReadOnly}
              value={brand.letters_code ?? ''}
              onChange={(e) =>
                setBrand({
                  ...brand,
                  ...{ letters_code: e.target.value },
                })
              }
            />
            <br />
            <Text as='sub' color='tomato'>
              変更するとリクルートURLが変わります。十分に注意してください。
            </Text>
            <Spacer size='16px' />

            <label name={'brand_url'}>アプリ導入ショップURL</label>
            <Spacer size='8px' />
            <Input
              name={'brand_url'}
              placeholder={''}
              htmlSize={10}
              width={700}
              isDisabled={isReadOnly}
              value={brand.url ?? ''}
              onChange={(e) =>
                setBrand({
                  ...brand,
                  ...{ url: e.target.value },
                })
              }
            />
            <Spacer size='16px' />
          </div>
        </div>

        <Spacer size='40px' />

        {
          // TODO: ログイン情報はブラインド
          false && (
            <div className={styles.section}>
              <h4 className={styles.section__title}>ログイン情報</h4>
              <Spacer size='24px' />

              <label name={'login_email'}>メールアドレス</label>
              <Spacer size='8px' />
              <Input
                name={'login_email'}
                placeholder={''}
                htmlSize={10}
                width={700}
                isDisabled={isReadOnly}
                value={userInfo.email ?? ''}
                onChange={(e) =>
                  setUserInfo({
                    ...userInfo,
                    ...{ email: e.target.value },
                  })
                }
              />
              <Spacer size='16px' />

              <label name={'login_password'}>パスワード</label>
              <Spacer size='8px' />
              <Input
                type='password'
                name={'login_password'}
                placeholder={'**********'}
                htmlSize={10}
                width={400}
                isDisabled={isReadOnly}
                value={''}
                onChange={(e) => {
                  setUserInfo({
                    ...userInfo,
                    ...{ password: e.target.value },
                  });
                }}
              />
              <Spacer size='16px' />

              {isChangedPassword && (
                <div>
                  <label name={'login_password_confirm'}>
                    パスワード再入力
                  </label>
                  <Spacer size='8px' />
                  <Input
                    type='password'
                    name={'login_password_confirm'}
                    placeholder={''}
                    htmlSize={10}
                    width={400}
                    value={''}
                    onChange={(e) =>
                      setUserInfo({
                        ...userInfo,
                        ...{ password_confirm: e.target.value },
                      })
                    }
                  />
                </div>
              )}
            </div>
          )
        }

        <Spacer size='40px' />

        {isReadOnly && (
          <Stack direction={'row'} align='center' justify='center'>
            <Button
              colorScheme='green'
              size={'lg'}
              width={450}
              className={styles.button}
              onClick={changeEdit}
            >
              編集する
            </Button>
          </Stack>
        )}
        {!isReadOnly && (
          <Stack direction={'row'} align='center' justify='center'>
            <Button
              colorScheme='green'
              size={'lg'}
              width={450}
              className={styles.button}
              onClick={save}
            >
              保存する
            </Button>
          </Stack>
        )}
      </Card>
    </>
  );
};
