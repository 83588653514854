'use client';

import { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { HelpText } from '@/frontend/components/elements/helpText/HelpText';
import { NextActionStepItem } from '@/frontend/components/feature/Home/NextActionStepItem';
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { ChevronRightIcon } from "@chakra-ui/icons";
import ValidationErrors from '@/frontend/components/elements/validation/ValidationErrors';
import { Card } from '@/frontend/components/elements/cards/Card';
import {
  Badge,
  Radio,
  RadioGroup,
  Input,
  Button,
  Checkbox,
  Stack,
  Select,
  useToast,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import Icon from '@mdi/react';
import { mdiThumbUpOutline } from '@mdi/js';

import styles from "./Step2.module.scss";
import {
  getAmbIncentive as getAmbIncentiveApi,
  postAmbIncentive as postAmbIncentiveApi,
  getCouponCode as getCouponCodeApi,
  postImportCouponCode as postImportCouponCodeApi,
  postImportCsv as postImportCsvApi,
  deleteCouponCode as deleteCouponCodeApi,
} from '@/frontend/src/backendApi';

export const Step2 = (props) => {
  const [type, setType] = useState('amazon');
  const [couponType, setCouponType] = useState('ratio');
  const [amazonGiftStoreType, setAmazonGiftStoreType] = useState('manual');
  const [couponStoreType, setCouponStoreType] = useState('manual');
  const [incentive, setIncentive] = useState({});
  const [incentiveInfo, setIncentiveInfo] = useState({});
  const [couponCodeList, setCouponCodeList] = useState([]);
  const [fileName, setFileName] = useState('CSVファイルをここにドロップ');
  const [file, setFile] = useState(null);

  // input
  const [inputMinPrice, setInputMinPrice] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [inputIncentiveGrace, setInputIncentiveGrace] = useState('');
  const [errors, setErrors] = useState({});
  const [inputCodeList, setInputCodeList] = useState('');
  const [inputAllowDuplicate, setInputAllowDuplicate] = useState(false);

  const toast = useToast();

  // アンバサダーインセンティブ情報取得
  const getAmbIncentive = async () => {
    const response = await getAmbIncentiveApi();
    console.log('amb_incentive');
    console.log(response.data.data);
    if ('amazon' in response.data.data.incentive) {
      setType('amazon');
      setIncentiveInfo(response.data.data.incentive.amazon);
      setInputCodeList('');
      setInputAllowDuplicate(false);
    } else if ('coupon' in response.data.data.incentive) {
      setType('coupon');
      setCouponType(
        response.data.data.incentive.coupon.origin_coupon_type == 1
          ? 'ratio'
          : 'fixed'
      );
      setIncentiveInfo(response.data.data.incentive.coupon);
      setInputCodeList('');
      setInputAllowDuplicate(false);
    } else if ('custom' in response.data.data.incentive) {
      setType('custom');
      setIncentiveInfo(response.data.data.incentive.custom);
      setInputCodeList('');
      setInputAllowDuplicate(false);
    } else {
      setType('none');
      setIncentiveInfo({});
      setInputCodeList('');
      setInputAllowDuplicate(false);
    }
    setIncentive(response.data.data.incentive ?? {});
  };

  // クーポンコード一覧取得
  const getCouponCode = async (couponType = '') => {
    let num = 99;
    let tempCouponType = couponType == '' ? type : couponType;
    switch (tempCouponType) {
      case 'amazon':
        num = 1;
        break;
      case 'coupon':
        num = 2;
        break;
      case 'custom':
        num = 3;
        break;
      default:
        num = 99;
    }
    if (num == 3 || num == 99) {
      return;
    }

    const response = await getCouponCodeApi({ type: num });
    console.log('coupon_code');
    console.log(response.data.data);
    setCouponCodeList(response.data.data.incentive_list ?? []);
  };

  const setInputDefault = useCallback(() => {
    if (type === 'amazon') {
      setInputMinPrice(
        incentive.amazon ? incentive.amazon.origin_min_price : ''
      );
      setInputValue(incentive.amazon ? incentive.amazon.origin_value : '');
      setInputIncentiveGrace(
        incentive.amazon ? incentive.amazon.origin_incentive_grace : ''
      );
    } else if (type === 'coupon') {
      setInputMinPrice(
        incentive.coupon ? incentive.coupon.origin_min_price : ''
      );
      setInputValue(incentive.coupon ? incentive.coupon.origin_value : '');
      setInputIncentiveGrace(
        incentive.coupon ? incentive.coupon.origin_incentive_grace : ''
      );
    } else if (type === 'custom') {
      setInputValue(incentive.custom ? incentive.custom.origin_min_price : '');
    } else {
      setInputValue('');
    }
    setErrors({});
  }, [incentive, type]);

  const save = async () => {
    // TODO: フロントバリデーション
    const params = {
      type: type,
      couponType: couponType,
      min_price: inputMinPrice == '' ? 0 : inputMinPrice,
      value: inputValue == '' ? 0 : inputValue,
      incentive_grace: inputIncentiveGrace == '' ? 0 : inputIncentiveGrace,
    };
    try {
      const response = await postAmbIncentiveApi(params);
      console.log(response);
      setErrors({});
    } catch (e) {
      console.error(e);
      setErrors(e.response.data.error ?? {});

      toast({
        title: '保存に失敗しました',
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }

    // 後処理
    (async () => {
      await getAmbIncentive();
    })();
  };

  const importIncentive = async () => {
    // TODO: フロントバリデーション
    let num = 99;
    switch (type) {
      case 'amazon':
        num = 1;
        break;
      case 'coupon':
        num = 2;
        break;
      case 'custom':
        num = 3;
        break;
      default:
        num = 99;
    }

    try {
      const response =
        amazonGiftStoreType === 'manual'
          ? await postImportCouponCodeApi({
              code_list: inputCodeList,
              allow_duplicate: inputAllowDuplicate ?? 0,
              type: num,
            })
          : await postImportCsvApi({
              file: file,
              allow_duplicate: inputAllowDuplicate ?? 0,
              type: num,
            });
      console.log(response);
    } catch (e) {
      console.error(e);
      setErrors(e.response.data.error ?? {}); // 重複エラー TODO:

      toast({
        title: '保存に失敗しました',
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }

    toast({
      title: '保存しました',
      status: 'success',
      duration: '3000',
      isClosable: true,
    });

    // 後処理
    setFileName('CSVファイルをここにドロップ');
    setFile(null);
    (async () => await getCouponCode())();
  };

  const deleteCode = async (id) => {
    if (
      !window.confirm(
        'インセンティブコードを削除します。元に戻せませんがよろしいですか？'
      )
    ) {
      return;
    }

    const params = { code_id: id };

    try {
      const response = await deleteCouponCodeApi(params);
      console.log(response);
    } catch (e) {
      console.error(e);
      toast({
        title: '削除に失敗しました',
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }

    toast({
      title: '削除しました',
      status: 'success',
      duration: '3000',
      isClosable: true,
    });
    // 後処理
    (async () => await getCouponCode())();
  };

  const csvDownload = () => {
    const records = [['code'], ['2VL2-SCC5FJ-XXXX'], ['MMGK-YF3C5D-XXXX']];
    let data = records.map((record) => record.join(',')).join('\r\n');

    // let bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    let blob = new Blob([data], { type: 'text/csv' });
    let url = (window.URL || window.webkitURL).createObjectURL(blob);
    let link = document.createElement('a');
    link.download = 'template.csv';
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // fetch
  useEffect(() => {
    getAmbIncentive();
    getCouponCode();
  }, []);

  // set input
  useEffect(() => {
    setInputDefault();
  }, [setInputDefault]);

  const typeText = () => {
    let text = '';
    switch (type) {
      case 'amazon':
        text =
          'Amazonでご使用いただけるギフト券をインセンティブとして設定できます';
        break;
      case 'coupon':
        text =
          '自社ECサイトでご使用いただけるクーポンをインセンティブとして設定できます';
        break;
      case 'custom':
        text = '社会貢献や限定イベントへの参加券など自由にカスタマイズできます';
        break;
      default:
        text = '';
    }
    return text;
  };

  // 日付形式変換
  function formatDatetime(date, sep = '/') {
    const yyyy = date.getFullYear();
    const mm = ('00' + (date.getMonth() + 1)).slice(-2);
    const dd = ('00' + date.getDate()).slice(-2);
    const h = ('00' + date.getHours()).slice(-2);
    const m = ('00' + date.getMinutes()).slice(-2);

    return `${yyyy}${sep}${mm}${sep}${dd} ${h}:${m}`;
  }

  const onDrop = useCallback((acceptedFiles) => {
    console.log('acceptedFiles:', acceptedFiles);
    setFileName(acceptedFiles[0].name ?? 'CSVファイルをここにドロップ');
    setFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <div className={styles.container}>
        <ul className={styles.step}>
          <NextActionStepItem
            step='1'
            title='インセンティブ設定'
            description='for アンバサダー'
            status='active'
          />
          <NextActionStepItem
            step='2'
            title='インセンティブ設定'
            description='for ゲスト'
            status=''
          />
          <NextActionStepItem
            step='3'
            title='運用スタート'
            description=''
            label=''
            status=''
          />
        </ul>

        <Spacer size='32px' />

        <p className={styles.title}>アンバサダー向けのインセンティブを設定できます</p>

        <Spacer size='32px' />

        <Card>
          <div className={styles.edit}>
            <div className={styles.edit__type}>
              <RadioGroup
                onChange={(v) => {
                  setType(v);
                  getCouponCode(v);
                }}
                value={type}
              >
                <Stack direction='row' className={styles.edit__type__stack}>
                  <Radio value='amazon'>
                    <Badge
                      colorScheme='green'
                      className={styles.edit__type__recommend}
                    >
                      おすすめ！
                    </Badge>
                    Amazonギフト券
                  </Radio>
                  <Radio value='coupon'>クーポン</Radio>
                  <Radio value='custom'>カスタム</Radio>
                  <Radio value='none'>なし</Radio>
                </Stack>
              </RadioGroup>
              {type !== 'none' && (
                <>
                  <Spacer size='18px' />
                  <div className={styles.edit__type__description}>
                    <p>{typeText()}</p>
                  </div>
                </>
              )}
            </div>

            <Spacer size='24px' />
            <ValidationErrors errors={errors} />

            {type === 'amazon' && (
              <>
                <div className={styles.edit__amount}>
                  <div className={styles.edit__title}>
                    1. ギフト金額
                    <Badge colorScheme='red'>必須</Badge>
                  </div>
                  <Spacer size='8px' />
                  <Stack direction={'row'} spacing={'8px'} align='center'>
                    <Input
                      placeholder='5000'
                      htmlSize={10}
                      width={180}
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                    <span>円</span>
                  </Stack>
                  <Spacer size='8px' />
                  <Stack
                    direction={'row'}
                    spacing={'8px'}
                    align='center'
                    className={styles.edit__advice}
                  >
                    <Icon
                      path={mdiThumbUpOutline}
                      size={'16px'}
                      color='rgba(128, 90, 213, 1)'
                    />
                    <span>設定アドバイス：商品価格の10〜15%を推奨しています</span>
                  </Stack>
                </div>

                <Spacer size='40px' />

                <div className={styles.edit__gift}>
                  <div className={styles.edit__title}>
                    2. アマゾンギフト新規登録
                    <Badge colorScheme='red'>必須</Badge>
                  </div>
                  <Spacer size='8px' />
                  <div className={styles.edit__gift__container}>
                    <RadioGroup
                      onChange={(v) => {
                        setFileName('CSVファイルをここにドロップ');
                        setFile(null);
                        setAmazonGiftStoreType(v);
                      }}
                      value={amazonGiftStoreType}
                    >
                      <Stack
                        direction='row'
                        gap={'0px'}
                        className={styles.edit__gift__stack}
                      >
                        <Radio value='manual'>手動登録</Radio>
                        <Radio value='csv'>CSVファイルから登録</Radio>
                      </Stack>
                    </RadioGroup>
                    <Spacer size='24px' />
                    <div className={styles.edit__gift__setting}>
                      {amazonGiftStoreType === 'manual' && (
                        <div className={styles.edit__gift__manual}>
                          <span>コード</span>
                          <Spacer size='8px' />
                          <Input
                            placeholder='00001,00002,00003'
                            htmlSize={10}
                            width={260}
                            value={inputCodeList}
                            onChange={(e) => setInputCodeList(e.target.value)}
                          />
                          <Spacer size='8px' />
                          <Stack
                            direction={'row'}
                            spacing={'8px'}
                            align='center'
                            className={styles.edit__advice}
                          >
                            <Icon
                              path={mdiThumbUpOutline}
                              size={'16px'}
                              color='rgba(128, 90, 213, 1)'
                            />
                            <span>
                              設定アドバイス：カンマ区切りでまとめて登録できます
                            </span>
                          </Stack>
                          <Spacer size='24px' />
                          <Checkbox
                            onChange={(e) => {
                              setInputAllowDuplicate(e.target.checked);
                            }}
                            checked={inputAllowDuplicate}
                          >
                            重複したコードの登録を許可する
                          </Checkbox>
                          <Spacer size='16px' />
                          <Button
                            size={'md'}
                            variant={'solid'}
                            colorScheme='blue'
                            width={140}
                            onClick={importIncentive}
                          >
                            登録
                          </Button>
                        </div>
                      )}

                      {amazonGiftStoreType === 'csv' && (
                        <div className={styles.edit__gift__csv}>
                          <div
                            {...getRootProps()}
                            className={`${styles.dropzone} ${
                              isDragActive && styles.dropzone__active
                            }`}
                          >
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <p>ここにファイルをドロップしてください</p>
                            ) : (
                              <p>{fileName}</p>
                            )}
                          </div>
                          <Spacer size='24px' />
                          <Checkbox
                            onChange={(e) => {
                              setInputAllowDuplicate(e.target.checked);
                            }}
                            checked={inputAllowDuplicate}
                          >
                            重複したコードの登録を許可する
                          </Checkbox>
                          <Spacer size='16px' />
                          <Stack direction={'row'} spacing={'8px'} align='center'>
                            <Button
                              size={'md'}
                              variant={'solid'}
                              colorScheme='blue'
                              width={140}
                              onClick={importIncentive}
                            >
                              登録
                            </Button>
                            <Button
                              size={'md'}
                              variant={'outline'}
                              colorScheme='gray'
                              onClick={csvDownload}
                            >
                              CSVテンプレートをダウンロード
                            </Button>
                          </Stack>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <Spacer size='40px' />

                <div className={styles.gift_list}>
                  <div className={styles.edit__title}>3. アマゾンギフト管理</div>
                  <Spacer size='8px' />

                  <div className={styles.table}>
                    <TableContainer maxWidth={'100%'}>
                      <Table>
                        <Thead>
                          <Tr>
                            <Th>コード</Th>
                            <Th>状態</Th>
                            <Th>発送先</Th>
                            <Th>付与日時</Th>
                            <Th width={'60px'}>操作</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {couponCodeList.map((v) => {
                            return (
                              <Tr key={v.id}>
                                <Td>{v.code}</Td>
                                <Td>{v.status == 1 ? '付与済み' : '未使用'}</Td>
                                <Td>{v.ambassadormail}</Td>
                                <Td>{formatDatetime(new Date(v.updated_at))}</Td>
                                <Td>
                                  <Stack direction={'row'} spacing={'4px'}>
                                    <Button
                                      size={'xs'}
                                      variant={'outline'}
                                      colorScheme={'red'}
                                      onClick={() => {
                                        if (v.status == 1) return;
                                        deleteCode(v.id);
                                      }}
                                    >
                                      削除
                                    </Button>
                                  </Stack>
                                </Td>
                              </Tr>
                            );
                          })}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>

                <Spacer size='40px' />

                <div className={styles.edit__min_amount}>
                  <div className={styles.edit__title}>
                    4. インセンティブ発生最小金額 (税込)
                    <HelpText label='ここに説明文' />
                  </div>
                  <Spacer size='8px' />
                  <Stack direction={'row'} spacing={'8px'} align='center'>
                    <Input
                      placeholder='0'
                      htmlSize={10}
                      width={180}
                      value={inputMinPrice}
                      onChange={(e) => setInputMinPrice(e.target.value)}
                    />
                    <span>円</span>
                  </Stack>
                  <Spacer size='8px' />
                  <Stack
                    direction={'row'}
                    spacing={'8px'}
                    align='center'
                    className={styles.edit__advice}
                  >
                    <Icon
                      path={mdiThumbUpOutline}
                      size={'16px'}
                      color='rgba(128, 90, 213, 1)'
                    />
                    <span>設定アドバイス：対象商品の金額を入力してください</span>
                  </Stack>
                </div>

                <Spacer size='40px' />

                <div className={styles.edit__period}>
                  <div className={styles.edit__title}>
                    5. インセンティブ発生猶予期間
                    <Badge colorScheme='red'>必須</Badge>
                    <HelpText label='ここに説明文' />
                  </div>
                  <Spacer size='8px' />
                  <Stack direction={'row'} spacing={'8px'} align='center'>
                    <Input
                      placeholder='14'
                      htmlSize={10}
                      width={180}
                      value={inputIncentiveGrace}
                      onChange={(e) => setInputIncentiveGrace(e.target.value)}
                    />
                    <span>日間</span>
                  </Stack>
                  <Spacer size='8px' />
                  <Stack
                    direction={'row'}
                    spacing={'8px'}
                    align='center'
                    className={styles.edit__advice}
                  >
                    <Icon
                      path={mdiThumbUpOutline}
                      size={'16px'}
                      color='rgba(128, 90, 213, 1)'
                    />
                    <span>
                      設定アドバイス：無条件返品期間などを設けている場合、その日数にあわせるなどを推奨しています。
                    </span>
                  </Stack>
                </div>
                <Spacer size='40px' />
              </>
            )}

            {type === 'coupon' && (
              <>
                <div className={styles.edit__amount}>
                  <div className={styles.edit__title}>
                    1. インセンティブ内容
                    <Badge colorScheme='red'>必須</Badge>
                  </div>
                  <Spacer size='8px' />
                  <Stack direction={'row'} spacing={'16px'} align='start'>
                    <Select
                      size='md'
                      defaultChecked={couponType}
                      onChange={(e) => setCouponType(e.target.value)}
                      width={140}
                    >
                      <option value='ratio'>割合</option>
                      <option value='fixed'>定額</option>
                    </Select>
                    <Stack direction={'row'} spacing={'8px'} align='center'>
                      <Input
                        placeholder='10'
                        htmlSize={10}
                        width={180}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                      />
                      <span>{couponType === 'ratio' ? '％' : '円'}</span>
                    </Stack>
                  </Stack>
                  <Spacer size='8px' />
                  <Stack
                    direction={'row'}
                    spacing={'8px'}
                    align='center'
                    className={styles.edit__advice}
                  >
                    <Icon
                      path={mdiThumbUpOutline}
                      size={'16px'}
                      color='rgba(128, 90, 213, 1)'
                    />
                    <span>
                      設定アドバイス：分かりやすくお得感を出すために1,000円OFFや10%OFFなどの設定をおすすめします。
                    </span>
                  </Stack>
                </div>

                <Spacer size='40px' />

                <div className={styles.edit__coupon}>
                  <div className={styles.edit__title}>
                    2. クーポンコード新規登録
                    <Badge colorScheme='red'>必須</Badge>
                  </div>
                  <Spacer size='8px' />
                  <div className={styles.edit__coupon__discount}>
                    <Stack direction={'row'} spacing={'8px'} align='center'>
                      <p>ショップのディスカウントページでコードを取得</p>
                      <HelpText label='ここに説明文' />
                    </Stack>
                    <Spacer size='10px' />
                    <Button
                      size='md'
                      variant='solid'
                      colorScheme='blue'
                      rightIcon={<ExternalLinkIcon />}
                      onClick={() => {
                        window.open('https://admin.shopify.com/discounts');
                      }}
                    >
                      ディスカウントページへ
                    </Button>
                  </div>
                  <Spacer size='16px' />
                  <div className={styles.edit__coupon__container}>
                    <RadioGroup
                      onChange={(v) => {
                        setFileName('CSVファイルをここにドロップ');
                        setFile(null);
                        setCouponStoreType(v);
                      }}
                      value={couponStoreType}
                    >
                      <Stack
                        direction='row'
                        gap={'0px'}
                        className={styles.edit__coupon__stack}
                      >
                        <Radio value='manual'>手動登録</Radio>
                        <Radio value='csv'>CSVファイルから登録</Radio>
                      </Stack>
                    </RadioGroup>
                    <Spacer size='24px' />
                    <div className={styles.edit__coupon__setting}>
                      {couponStoreType === 'manual' && (
                        <div className={styles.edit__coupon__manual}>
                          <span>コード</span>
                          <Spacer size='8px' />
                          <Input
                            placeholder='00001,00002,00003'
                            htmlSize={10}
                            width={260}
                            value={inputCodeList}
                            onChange={(e) => setInputCodeList(e.target.value)}
                          />
                          <Spacer size='8px' />
                          <Stack
                            direction={'row'}
                            spacing={'8px'}
                            align='center'
                            className={styles.edit__advice}
                          >
                            <Icon
                              path={mdiThumbUpOutline}
                              size={'16px'}
                              color='rgba(128, 90, 213, 1)'
                            />
                            <span>
                              設定アドバイス：カンマ区切りでまとめて登録できます
                            </span>
                          </Stack>
                          <Spacer size='24px' />
                          <Checkbox
                            onChange={(e) => {
                              setInputAllowDuplicate(e.target.checked);
                            }}
                            checked={inputAllowDuplicate}
                          >
                            重複したコードの登録を許可する
                          </Checkbox>
                          <Spacer size='16px' />
                          <Button
                            size={'md'}
                            variant={'solid'}
                            colorScheme='blue'
                            width={140}
                            onClick={importIncentive}
                          >
                            登録
                          </Button>
                        </div>
                      )}

                      {couponStoreType === 'csv' && (
                        <div className={styles.edit__coupon__csv}>
                          <div
                            {...getRootProps()}
                            className={`${styles.dropzone} ${
                              isDragActive && styles.dropzone__active
                            }`}
                          >
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <p>ここにファイルをドロップしてください</p>
                            ) : (
                              <p>{fileName}</p>
                            )}
                          </div>
                          <Spacer size='24px' />
                          <Checkbox
                            onChange={(e) => {
                              setInputAllowDuplicate(e.target.checked);
                            }}
                            checked={inputAllowDuplicate}
                          >
                            重複したコードの登録を許可する
                          </Checkbox>
                          <Spacer size='16px' />
                          <Stack direction={'row'} spacing={'8px'} align='center'>
                            <Button
                              size={'md'}
                              variant={'solid'}
                              colorScheme='blue'
                              width={140}
                              onClick={importIncentive}
                            >
                              登録
                            </Button>
                            <Button
                              size={'md'}
                              variant={'outline'}
                              colorScheme='gray'
                              onClick={csvDownload}
                            >
                              CSVテンプレートをダウンロード
                            </Button>
                          </Stack>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <Spacer size='40px' />

                <div className={styles.gift_list}>
                  <div className={styles.edit__title}>3. クーポンコード管理</div>
                  <Spacer size='8px' />

                  <div className={styles.table}>
                    <TableContainer maxWidth={'100%'}>
                      <Table>
                        <Thead>
                          <Tr>
                            <Th>コード</Th>
                            <Th>状態</Th>
                            <Th>発送先</Th>
                            <Th>付与日時</Th>
                            <Th width={'60px'}>操作</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {couponCodeList.map((v) => {
                            return (
                              <Tr key={v.id}>
                                <Td>{v.code}</Td>
                                <Td>{v.status == 1 ? '付与済み' : '未使用'}</Td>
                                <Td>{v.ambassadormail}</Td>
                                <Td>{formatDatetime(new Date(v.updated_at))}</Td>
                                <Td>
                                  <Stack direction={'row'} spacing={'4px'}>
                                    <Button
                                      size={'xs'}
                                      variant={'outline'}
                                      colorScheme={'red'}
                                      onClick={() => {
                                        if (v.status == 1) return;
                                        deleteCode(v.id);
                                      }}
                                    >
                                      削除
                                    </Button>
                                  </Stack>
                                </Td>
                              </Tr>
                            );
                          })}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>

                <Spacer size='40px' />

                <div className={styles.edit__min_amount}>
                  <div className={styles.edit__title}>
                    4. インセンティブ発生最小金額 (税込)
                    <HelpText label='ここに説明文' />
                  </div>
                  <Spacer size='8px' />
                  <Stack direction={'row'} spacing={'8px'} align='center'>
                    <Input
                      placeholder='0'
                      htmlSize={10}
                      width={180}
                      value={inputMinPrice}
                      onChange={(e) => setInputMinPrice(e.target.value)}
                    />
                    <span>円</span>
                  </Stack>
                  <Spacer size='8px' />
                  <Stack
                    direction={'row'}
                    spacing={'8px'}
                    align='center'
                    className={styles.edit__advice}
                  >
                    <Icon
                      path={mdiThumbUpOutline}
                      size={'16px'}
                      color='rgba(128, 90, 213, 1)'
                    />
                    <span>
                      設定アドバイス：インセンティブが発生する最低金額を決めてください。
                    </span>
                  </Stack>
                </div>

                <Spacer size='40px' />

                <div className={styles.edit__period}>
                  <div className={styles.edit__title}>
                    5. インセンティブ発生猶予期間
                    <Badge colorScheme='red'>必須</Badge>
                    <HelpText label='ここに説明文' />
                  </div>
                  <Spacer size='8px' />
                  <Stack direction={'row'} spacing={'8px'} align='center'>
                    <Input
                      placeholder='14'
                      htmlSize={10}
                      width={180}
                      value={inputIncentiveGrace}
                      onChange={(e) => setInputIncentiveGrace(e.target.value)}
                    />
                    <span>日間</span>
                  </Stack>
                  <Spacer size='8px' />
                  <Stack
                    direction={'row'}
                    spacing={'8px'}
                    align='center'
                    className={styles.edit__advice}
                  >
                    <Icon
                      path={mdiThumbUpOutline}
                      size={'16px'}
                      color='rgba(128, 90, 213, 1)'
                    />
                    <span>
                      設定アドバイス：無条件返品期間などを設けている場合、その日数にあわせるなどを推奨しています。
                    </span>
                  </Stack>
                </div>
                <Spacer size='40px' />
              </>
            )}

            {type === 'custom' && (
              <>
                <div className={styles.edit__amount}>
                  <div className={styles.edit__title}>
                    1. インセンティブ内容
                    <Badge colorScheme='red'>必須</Badge>
                  </div>
                  <Spacer size='8px' />
                  <Input
                    type='text'
                    placeholder='売上の○%を▲▲に寄付'
                    htmlSize={10}
                    width={300}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  <Spacer size='8px' />
                  <Stack
                    direction={'row'}
                    spacing={'8px'}
                    align='center'
                    className={styles.edit__advice}
                  >
                    <Icon
                      path={mdiThumbUpOutline}
                      size={'16px'}
                      color='rgba(128, 90, 213, 1)'
                    />
                    <span>おすすめ設定：売上の○%を▲▲に寄付</span>
                  </Stack>
                </div>
                <Spacer size='40px' />
              </>
            )}
          </div>
        </Card>

        <Spacer size='48px' />

        <Stack
          direction={'row'}
          spacing={'10px'}
          align='center'
          justify='center'
        >
          <Button
            size="lg"
            variant="outline"
            colorScheme="blue"
            leftIcon={<ChevronLeftIcon />}
            width={220}
            onClick={
              () => {
                props.onBack();
              }
            }
          >
            前の画面に戻る
          </Button>
          <Button
            size="lg"
            variant="solid"
            colorScheme="blue"
            rightIcon={<ChevronRightIcon />}
            width={220}
            onClick={
              async () => {
                await save();
                props.onNext();
              }
            }
          >
            保存して次へ
          </Button>
        </Stack>

        <Spacer size='32px' />

        <a
          href='#'
          className={styles.textLink}
          onClick={
            async () => {
              await save();
              props.onSkip();
            }
          }
        >
          保存してホームに戻る
        </a>
      </div>
    </>
  );
};