'use client';

import { useState, useEffect } from 'react';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { HelpText } from '@/frontend/components/elements/helpText/HelpText';
import { NextActionStepItem } from '@/frontend/components/feature/Home/NextActionStepItem';
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Card } from '@/frontend/components/elements/cards/Card';
import {
  Button,
  Stack,
} from '@chakra-ui/react';
import {
  getAmbIncentive as getAmbIncentiveApi,
  getGuestIncentive as getGuestIncentiveApi,
} from '@/frontend/src/backendApi';
import iconAmbassador from '/icons/icon-ambassador.svg';
import iconGuest from '/icons/icon-guest.svg';

import styles from "./Step4.module.scss";
export const Step4 = (props) => {
  const [ambIncentiveInfo, setAmbIncentiveInfo] = useState({});
  const [guestIncentiveInfo, setGuestIncentiveInfo] = useState({});

  // アンバサダーインセンティブ情報取得
  const getAmbIncentive = async () => {
    const response = await getAmbIncentiveApi();
    if ('amazon' in response.data.data.incentive) {
      setAmbIncentiveInfo(response.data.data.incentive.amazon);
    } else if ('coupon' in response.data.data.incentive) {
      setAmbIncentiveInfo(response.data.data.incentive.coupon);
    } else if ('custom' in response.data.data.incentive) {
      setAmbIncentiveInfo(response.data.data.incentive.custom);
    } else {
      setAmbIncentiveInfo({});
    }
  };

  // ゲストインセンティブ情報取得
  const getGuestIncentive = async () => {
    const response = await getGuestIncentiveApi();

    if ('coupon' in response.data.data.incentive) {
      setGuestIncentiveInfo(response.data.data.incentive.coupon);
    } else {
      setGuestIncentiveInfo({});
    }
  };

  // fetch
  useEffect(() => {
    getAmbIncentive();
    getGuestIncentive();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <ul className={styles.step}>
          <NextActionStepItem
            step='1'
            title='インセンティブ設定'
            description='for アンバサダー'
            status='done'
          />
          <NextActionStepItem
            step='2'
            title='インセンティブ設定'
            description='for ゲスト'
            status='done'
          />
          <NextActionStepItem
            step='3'
            title='運用スタート'
            description=''
            label=''
            status='active'
          />
        </ul>

        <Spacer size='32px' />

        <p className={styles.title}>STEP1,2で入力した内容の確認を行ってください。</p>

        <Spacer size='32px' />

        <Card title="インセンティブ設定">
          <Spacer size='32px' />
          <Stack direction='row' spacing='2px' align='center' justify='left' className={styles.subtitle}>
            <img src={iconAmbassador} />
            <p>アンバサダー</p>
          </Stack>
          <Spacer size='8px' />

          <div className={styles.info}>
            <table>
              <tbody>
                <tr>
                  <th>インセンティブタイプ</th>
                  <td>{ambIncentiveInfo.type ?? '-'}</td>
                </tr>
                <tr>
                  <th>インセンティブ内容</th>
                  <td>{ambIncentiveInfo.body ?? '-'}</td>
                </tr>
                <tr>
                  <th>インセンティブ発生最小金額 (税込)</th>
                  <td>{ambIncentiveInfo.min_price ?? '-'}</td>
                </tr>
                <tr>
                  <th>
                    インセンティブ発生猶予期間
                    <HelpText
                      label={
                        <div>
                          アンバサダーの成果(CV)確定までの猶予期間を設定してください。
                          <br />
                          ゲストの購入成立後からアンバサダーへのインセンティブ付与までの間に猶予期間を設けることが可能です。
                          <br />
                          キャンセル有効期間などに配慮の上、自由に設定してください。
                        </div>
                      }
                    />
                  </th>
                  <td>{ambIncentiveInfo.incentive_grace ?? '-'}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <Spacer size='32px' />
          <Stack direction='row' spacing='2px' align='center' justify='left' className={styles.subtitle}>
            <img src={iconGuest} />
            <p>ゲスト</p>
          </Stack>
          <Spacer size='8px' />

          <div className={styles.info}>
            <table>
              <tbody>
                <tr>
                  <th>インセンティブタイプ</th>
                  <td>{guestIncentiveInfo.type ?? '-'}</td>
                </tr>
                <tr>
                  <th>インセンティブ内容</th>
                  <td>{guestIncentiveInfo.body ?? '-'}</td>
                </tr>
                <tr>
                  <th>インセンティブ発生最小金額 (税込)</th>
                  <td>{guestIncentiveInfo.min_price ?? '-'}</td>
                </tr>
                <tr>
                  <th>クーポンコード</th>
                  <td>{guestIncentiveInfo.coupon_code ?? '-'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Card>

        <Spacer size='48px' />

        <Stack
          direction={'row'}
          spacing={'10px'}
          align='center'
          justify='center'
        >
          <Button
            size="lg"
            variant="outline"
            colorScheme="blue"
            leftIcon={<ChevronLeftIcon />}
            width={220}
            onClick={
              () => {
                props.onBack();
              }
            }
          >
            前の画面に戻る
          </Button>
          <Button
            size="lg"
            variant="solid"
            colorScheme="blue"
            rightIcon={<ChevronRightIcon />}
            width={220}
            onClick={
              async () => {
                props.onNext();
              }
            }
          >
            保存して設定完了
          </Button>
        </Stack>

        <Spacer size='32px' />

        <a
          href='#'
          className={styles.textLink}
          onClick={
            async () => {
              props.onSkip();
            }
          }
        >
          保存してホームに戻る
        </a>
      </div>
    </>
  );
};