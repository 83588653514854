"use client";

import { useState, useEffect } from "react";
import { Modal } from "../../elements/modal/Modal";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import { Step4 } from "./Step4";
import { Step5 } from "./Step5";

export const Tutorial = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (!props.isSettingProgressInitialized) {
      if (!props.ambSettingProgress && !props.guestSettingProgress) {
        setStep(1);
        setIsOpen(true);
      } else if (props.ambSettingProgress && !props.guestSettingProgress) {
        setStep(3);
        setIsOpen(true);
      } else if (!props.ambSettingProgress && props.guestSettingProgress) {
        setStep(1);
        setIsOpen(true);
      } else if (props.ambSettingProgress && props.guestSettingProgress) {
        setIsOpen(false);
      }

      props.setIsSettingProgressInitialized(true);
    }
  }, [props]);

  return (
    <>
      <Modal isOpen={isOpen}>
        {step === 1 && (
          <Step1 onNext={() => setStep(2)} onSkip={() => setIsOpen(false)} />
        )}
        {step === 2 &&
          <Step2
            onBack={() => setStep(1)}
            onNext={() => {
              props.setIsSettingProgressUpdated(true);
              setStep(3);
            }}
            onSkip={() => {
              props.setIsSettingProgressUpdated(true);
              setIsOpen(false);
            }}
          />
        }
        {step === 3 &&
          <Step3
            onBack={() => setStep(2)}
            onNext={() => {
              props.setIsSettingProgressUpdated(true);
              setStep(4);
            }}
            onSkip={() => {
              props.setIsSettingProgressUpdated(true);
              setIsOpen(false);
            }}
          />
        }
        {step === 4 &&
          <Step4
            onBack={() => setStep(3)}
            onNext={() => {
              props.setIsSettingProgressUpdated(true);
              setStep(5);
            }}
            onSkip={() => {
              props.setIsSettingProgressUpdated(true);
              setIsOpen(false);
            }}
          />
        }
        {step === 5 &&
          <Step5 onSkip={() => setIsOpen(false)} />
        }
      </Modal>
    </>
  );
};
