'use client';

import { Button, Badge, HStack } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import iconAmbassador from '/icons/icon-ambassador.svg';
import iconGuest from '/icons/icon-guest.svg';

import styles from './Card.module.scss';

export const Card = (props) => {
  return (
    <div
      id={props.id}
      className={`${styles.card} ${props.className}`}
      style={props.style}
    >
      {props.title && (
        <div className={styles.card__header}>
          <HStack align={'center'} justify={'space-between'} width={'100%'}>
            <HStack align={'center'} justify={'start'}>
              <div className={styles.card__title}>{props.title}</div>
              {props.helpTitle && (
                <Button
                  variant='outline'
                  colorScheme='blue'
                  size='sm'
                  rightIcon={<ExternalLinkIcon />}
                >
                  {props.helpTitle}
                </Button>
              )}
              {props.forAmbassador && (
                <Badge
                  className={`${styles.badge} ${styles.badge__ambassador}`}
                >
                  <img
                    src={iconAmbassador}
                    alt={props.title}
                    className={styles.icon}
                  />
                  アンバサダー
                </Badge>
              )}
              {props.forGuest && (
                <Badge className={`${styles.badge} ${styles.badge__guest}`}>
                  <img
                    src={iconGuest}
                    alt={props.title}
                    className={styles.icon}
                  />
                  ゲスト
                </Badge>
              )}
            </HStack>
            {props.action && <div>{props.action}</div>}
          </HStack>
        </div>
      )}
      {props.children}
    </div>
  );
};
