export const Spacer = (props) => {
  return (
    <div
      style={
        props.horizontal
          ? { width: props.size, height: 'auto', display: 'inline-block', flexShrink: 0 }
          : { width: 'auto', height: props.size, flexShrink: 0  }
      }
    />
  )
};
