"use client";

import { useState, useEffect } from "react";
import iconClose from "/icons/icon-close.svg";
import styles from "./Modal.module.scss";

export const Modal = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen || true);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  return (
    <>
      {isOpen && (
        <>
          <div className={styles.overlay} onClink={() => setIsOpen(false)} />
          <div className={styles.modal}>
            <div className={styles.close} onClick={() => setIsOpen(false)}>
              <img src={iconClose} alt="閉じる" />
            </div>
            <div className={styles.content}>
              {props.children}
            </div>
          </div>
        </>
      )}
    </>
  );
};
