import { useState, useEffect } from 'react';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { Card } from '@/frontend/components/elements/cards/Card';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';

import styles from './Block.module.scss';
import { getBlockList as getBlockListApi } from '@/frontend/src/backendApi';

export const BlockScreen = () => {
  const [blockList, setBlockList] = useState([]);

  // ブロック一覧取得
  const getBlockList = async () => {
    const response = await getBlockListApi();
    console.log('get_block_list');
    console.log(response.data.data);
    setBlockList(response.data.data.block_list ?? []);

    // TODO: ページングを意識した実装
  };

  // 日付形式変換
  function formatDatetime(date, sep = '/') {
    const yyyy = date.getFullYear();
    const mm = ('00' + (date.getMonth() + 1)).slice(-2);
    const dd = ('00' + date.getDate()).slice(-2);
    const h = ('00' + date.getHours()).slice(-2);
    const m = ('00' + date.getMinutes()).slice(-2);

    return `${yyyy}${sep}${mm}${sep}${dd} ${h}:${m}`;
  }

  // fetch
  useEffect(() => {
    getBlockList();
  }, []);

  return (
    <>
      <h2 className={styles.title}>アンバサダー管理</h2>
      <Spacer size='16px' />
      <h3 className={styles.subtitle}>メールブロック一覧</h3>
      <Spacer size='8px' />
      <p className={styles.description}>
        ここではLetters経由のメール案内を解除した方のメールアドレスをリスト化しています。
        <br />
        以下のメールアドレスへはLettersからのメール案内はされません。
      </p>

      <Spacer size='24px' />

      <Card>
        <div className={styles.table}>
          <TableContainer maxWidth={'100%'}>
            <Table>
              <Thead>
                <Tr>
                  <Th>メールアドレス</Th>
                  <Th textAlign={'right'}>解除日時</Th>
                </Tr>
              </Thead>
              <Tbody>
                {blockList.map((v) => {
                  return (
                    <Tr key={v.id}>
                      <Td>{v.email}</Td>
                      <Td textAlign={'right'}>
                        {formatDatetime(new Date(v.created_at))}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      </Card>
    </>
  );
};
