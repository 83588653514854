import { useState, useEffect } from 'react';
import { Grid } from '@chakra-ui/react';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { Card } from '@/frontend/components/elements/cards/Card';
import { ActivateProgram } from '@/frontend/components/feature/Home/ActivateProgram';
import { Incentive } from '@/frontend/components/feature/Home/Incentive';
import { NextAction } from '@/frontend/components/feature/Home/NextAction';
import { BannerSection } from '@/frontend/components/feature/Home/BannerSection';
import { ConversionTable } from '@/frontend/components/feature/Home/ConversionTable';
import { Review } from '@/frontend/components/feature/Home/Review';
import { AmbassadorRanking } from '@/frontend/components/feature/Home/AmbassadorRanking';
import { BlindModal } from '@/frontend/components/elements/modal/BlindModal';
import { Tutorial } from "@/frontend/components/layouts/tutorial/Tutorial";

import styles from './Home.module.scss';
import {
  getHomeReport as getReportApi,
  getIncentive as getIncentiveApi,
  getProgramState as getProgramStateApi,
  postProgramState as postProgramStateApi,
  getSettingProgress as getSettingProgressApi,
  getSettingLoginInfo as getSettingLoginInfoApi,
  getAmbassadorRanking as getAmbassadorRankingApi,
} from '@/frontend/src/backendApi';

export const HomeScreen = (props) => {
  const [report, setReport] = useState({});
  const [ambIncentive, setAmbIncentive] = useState('-');
  const [guestIncentive, setGuestIncentive] = useState('-');
  const [programSate, setProgramState] = useState(false);
  const [ambSettingProgress, setAmbSettingProgress] = useState(false);
  const [guestSettingProgress, setGuestSettingProgress] = useState(false);
  const [isSettingProgressLoaded, setIsSettingProgressLoaded] = useState(false);
  const [isSettingProgressUpdated, setIsSettingProgressUpdated] = useState(false);
  const [isSettingProgressInitialized, setIsSettingProgressInitialized] = useState(false);
  const [currentRole, setCurrentRole] = useState(3);
  const [currentPlan, setCurrentPlan] = useState(1);
  const [ambassadorRanking, setAmbassadorRanking] = useState([]);

  // 直近1ヶ月の成果取得
  const getReport = async () => {
    const response = await getReportApi();
    console.log('report');
    console.log(response.data.data);
    setReport({ ...response.data.data });
  };

  // インセンティブ取得
  const getIncentive = async () => {
    const response = await getIncentiveApi();
    console.log('incentive');
    console.log(response.data.data);
    setAmbIncentive(response.data.data.amb_incentive ?? '-');
    setGuestIncentive(response.data.data.guest_incentive ?? '-');
  };

  // プログラム有効状態取得
  const getProgramState = async () => {
    const response = await getProgramStateApi();
    console.log('program_state');
    console.log(response.data.data);
    setProgramState(response.data.data.enable);
  };
  // プログラム有効/無京
  const postProgramState = async (state) => {
    const response = await postProgramStateApi(state);
    console.log('program_state');
    console.log(response.data.data);
    setProgramState(response.data.data.enable);
  };

  // 簡単スタート設定
  const getSettingProgress = async () => {
    const response = await getSettingProgressApi();
    console.log('setting_progress');
    console.log(response.data.data);
    setAmbSettingProgress(response.data.data.is_amb_setting ?? false);
    setGuestSettingProgress(response.data.data.is_guest_setting ?? false);
    setIsSettingProgressLoaded(true);
  };

  // ログイン情報取得
  const getSettingLoginInfo = async () => {
    const response = await getSettingLoginInfoApi();
    console.log('login_info');
    console.log(response.data.data);
    setCurrentRole(response.data.data.current_role ?? 3);
    setCurrentPlan(response.data.data.current_plan ?? 0);
  };

  // アンバサダーランキング取得
  const getAmbassadorRanking = async () => {
    const response = await getAmbassadorRankingApi();
    console.log('ranking');
    console.log(response.data.data);
    setAmbassadorRanking({ ...response.data.data });
  };

  // fetch
  useEffect(() => {
    console.log('props');
    console.log(props);
    getSettingLoginInfo();
    // 直近1ヶ月の成果情報
    getReport();
    getIncentive();
    getProgramState();
    getSettingProgress();
    getAmbassadorRanking();
  }, []);

  // チュートリアル完了後のデータ更新
  useEffect(() => {
    if (isSettingProgressLoaded) {
      getSettingProgress();
      setIsSettingProgressUpdated(false);
    }
  }, [isSettingProgressUpdated]);

  return (
    <>
      {currentRole != 1 && currentPlan == 0 && (
        // Letters管理者以外 かつ 旧プランの場合移行モーダル表示
        <>
          <BlindModal />
        </>
      )}

      <h2 className={styles.title}>ホーム</h2>
      <Spacer size='24px' />

      <Grid templateColumns='1fr 2fr' gap='16px'>
        <ActivateProgram
          programSate={programSate}
          postProgramState={postProgramState}
        />
        <Incentive
          ambIncentive={ambIncentive}
          guestIncentive={guestIncentive}
        />
      </Grid>

      <Spacer size='32px' />

      {isSettingProgressLoaded && (
        <>
          <NextAction
            ambSettingProgress={ambSettingProgress}
            guestSettingProgress={guestSettingProgress}
          />
          <Spacer size='32px' />
        </>
      )}

      <BannerSection />

      <Spacer size='32px' />

      <Card title='直近1ヶ月の成果（前月同日から本日まで）'>
        <ConversionTable
          isActivated={true}
          report={report}
          getReport={getReport}
        />
      </Card>

      <Spacer size='32px' />

      <Card>
        <Review />
      </Card>

      <Spacer size='32px' />

      {/* <Card title='月間アンバサダーランキング' helpTitle='ランキングについて'> */}
      <Card title='月間アンバサダーランキング'>
        <AmbassadorRanking data={ambassadorRanking} />
      </Card>

      {isSettingProgressLoaded && (
        <Tutorial
          isSettingProgressInitialized={isSettingProgressInitialized}
          setIsSettingProgressInitialized={setIsSettingProgressInitialized}
          setIsSettingProgressUpdated={setIsSettingProgressUpdated}
          ambSettingProgress={ambSettingProgress}
          guestSettingProgress={guestSettingProgress}
        />
      )}
    </>
  );
};
