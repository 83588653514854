'use client';

import { useState, useEffect } from 'react';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { Card } from '@/frontend/components/elements/cards/Card';
import { HelpText } from '@/frontend/components/elements/helpText/HelpText';
import { CustomEditor } from '@/frontend/components/elements/editor/CustomEditor';
import { ImageLibrary } from '@/frontend/components/elements/imageLibrary/ImageLibrary';
import { Button, Stack, Input, useToast } from '@chakra-ui/react';
import { ChevronUpIcon } from '@chakra-ui/icons';
import { SketchPicker } from 'react-color';
import ValidationErrors from '@/frontend/components/elements/validation/ValidationErrors';

import styles from './Ambassador.module.scss';
import {
  getAmbDocument as getAmbDocumentApi,
  postAmbDocument as postAmbDocumentApi,
  postGeneratePreviewHtml as postGeneratePreviewHtmlApi,
} from '@/frontend/src/backendApi';

export const AmbassadorScreen = () => {
  const toast = useToast();
  const [previewDeviceType, setPreviewDeviceType] = useState('mobile');
  const [editDeviceType, setEditDeviceType] = useState('mobile');
  const [isEdit, setIsEdit] = useState(false);
  // const [text, setText] = useState('');
  const [displayAmbassadorColorPicker, setDisplayAmbassadorColorPicker] =
    useState(false);
  const [displayGuestColorPicker, setDisplayGuestColorPicker] = useState(false);

  const [html, setHtml] = useState('');
  const [page_type, setPageType] = useState(1);
  const [logoImagePath, setLogoImagePath] = useState('');
  const [mainImagePath, setMainImagePath] = useState('');
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [description, setDescription] = useState('');
  const [joinHelp, setJoinHelp] = useState('');
  const [coupon, setCoupon] = useState([]);
  const [errors, setErrors] = useState({});

  // アンバサダーページ情報取得
  const getAmbDocument = async () => {
    const response = await getAmbDocumentApi();
    console.log('amb_document');
    console.log(response.data.data);

    postGeneratePreviewHtmlApi({
      layout: 'ambassador',
      html: response.data.data.html,
    }).then((response) => {
      setHtml(response.data);
    });

    setPageType(response.data.data.page_type ?? 1);

    const inputs = response.data.data.input_elements ?? [];
    // setInputElement(inputs);

    // 入力値セット
    const logoImgObj = inputs.filter((item) => item.id === 'logoimg')[0];
    setLogoImagePath(logoImgObj ? logoImgObj['value'] : '');
    const mainImgObj = inputs.filter((item) => item.id === 'mainimg')[0];
    setMainImagePath(mainImgObj ? mainImgObj['value'] : '');
    const titleObj = inputs.filter((item) => item.id === 'title')[0];
    setTitle(titleObj ? titleObj['value'] : '');
    const subTitleObj = inputs.filter((item) => item.id === 'subtitle')[0];
    setSubTitle(subTitleObj ? subTitleObj['value'] : '');
    const descriptionObj = inputs.filter(
      (item) => item.id === 'description'
    )[0];
    setDescription(descriptionObj ? descriptionObj['value'] : '');
    const joinHelpObj = inputs.filter((item) => item.id === 'joinhelp')[0];
    setJoinHelp(joinHelpObj ? joinHelpObj['value'] : '');
    const ambIncentiveTitleObj = inputs.filter(
      (item) => item.id === 'amb_incen_title'
    )[0];
    const ambIncentiveObj = inputs.filter((item) => item.id === 'amb_incen')[0];
    const ambIncentiveColorObj = inputs.filter(
      (item) => item.id === 'amb_incentive_color'
    )[0];
    const guestIncentiveTitleObj = inputs.filter(
      (item) => item.id === 'gst_incen_title'
    )[0];
    const guestIncentiveObj = inputs.filter(
      (item) => item.id === 'gst_incen'
    )[0];
    const guestIncentiveColorObj = inputs.filter(
      (item) => item.id === 'guest_incentive_color'
    )[0];
    setCoupon({
      amb_incentive_title: ambIncentiveTitleObj
        ? ambIncentiveTitleObj['value']
        : '',
      amb_incentive: ambIncentiveObj ? ambIncentiveObj['value'] : '',
      amb_incentive_color: ambIncentiveColorObj
        ? ambIncentiveColorObj['value']
        : '',
      guest_incentive_title: guestIncentiveTitleObj
        ? guestIncentiveTitleObj['value']
        : '',
      guest_incentive: guestIncentiveObj ? guestIncentiveObj['value'] : '',
      guest_incentive_color: guestIncentiveColorObj
        ? guestIncentiveColorObj['value']
        : '',
    });
  };

  const changeHtml = (id, v) => {
    if (html === '') {
      return;
    }

    // HTML文字列を一時的にDOM要素に変換
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    switch (id) {
      case 'logoimg':
        {
          const element = tempDiv.querySelector('[data-id="logoimg"]');
          const img = element.querySelector('img');
          img.src = v;
        }
        break;
      case 'mainimg':
        {
          const element = tempDiv.querySelector('[data-id="mainimg"]');
          const img = element.querySelector('img');
          img.src = v;
        }
        break;
      case 'title':
        {
          const element = tempDiv.querySelector('[data-id="title"]');
          element.textContent = v;
        }
        break;
      case 'subtitle':
        {
          const element = tempDiv.querySelector('[data-id="subtitle"]');
          element.textContent = v;
        }
        break;
      case 'description':
        {
          const element = tempDiv.querySelector('[data-id="description"]');
          if (!element) {
            break;
          }
          element.innerHTML = v;
        }
        break;
      case 'joinhelp':
        {
          const element = tempDiv.querySelector('[data-id="joinhelp"]');
          if (!element) {
            break;
          }
          element.innerHTML = v;
        }
        break;
      case 'amb_incentive_title':
        {
          const element = tempDiv.querySelector(
            '[data-id="amb_incen"]'
          ).previousElementSibling;
          element.textContent = v;
        }
        break;
      case 'amb_incentive':
        {
          const element = tempDiv.querySelector('[data-id="amb_incen"]');
          element.textContent = v;
        }
        break;
      case 'amb_incentive_color':
        {
          const card = tempDiv.querySelectorAll('.card')[0]; // アンバサダーカード
          card.style.backgroundColor = v;
          card.style.borderColor = v;
        }
        break;
      case 'guest_incentive_title':
        {
          const element = tempDiv.querySelector(
            '[data-id="gst_incen"]'
          ).previousElementSibling;
          element.textContent = v;
        }
        break;
      case 'guest_incentive':
        {
          const element = tempDiv.querySelector('[data-id="gst_incen"]');
          element.textContent = v;
        }
        break;
      case 'guest_incentive_color':
        {
          const card = tempDiv.querySelectorAll('.card')[1]; // ゲストカード
          card.style.backgroundColor = v;
          card.style.borderColor = v;
        }
        break;
    }
    setHtml(tempDiv.innerHTML);
  };

  const save = async () => {
    // TODO: フロントバリデーション
    const params = {
      page_params: {
        'img:logoimg': logoImagePath,
        'img:mainimg': mainImagePath,
        'text:title': title,
        'text:subtitle': subTitle,
        'text:description': description,
        'text:joinhelp': joinHelp,
        'text:amb_incen_title': coupon.amb_incentive_title,
        'text:amb_incen': coupon.amb_incentive,
        'color:amb_incentive_color': coupon.amb_incentive_color,
        'text:gst_incen_title': coupon.guest_incentive_title,
        'text:gst_incen': coupon.guest_incentive,
        'color:guest_incentive_color': coupon.guest_incentive_color,
        'img:footer_logoimg': '',
      },
    };
    console.log(params);

    try {
      const response = await postAmbDocumentApi(params);
      console.log(response);
      setErrors({});
    } catch (e) {
      console.error(e);
      setErrors(e.response.data.error ?? {});

      toast({
        title: '保存に失敗しました',
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }

    // 後処理
    window.scrollTo({ top: 0, behavior: 'smooth' });
    toast({
      title: '保存しました',
      status: 'success',
      duration: '3000',
      isClosable: true,
    });
    (async () => await getAmbDocument())();
  };

  // fetch
  useEffect(() => {
    getAmbDocument();
  }, []);

  useEffect(() => {
    changeHtml('logoimg', logoImagePath);
  }, [logoImagePath]);

  useEffect(() => {
    changeHtml('mainimg', mainImagePath);
  }, [mainImagePath]);

  return (
    <>
      <h2 className={styles.title}>ドキュメント管理</h2>
      <Spacer size='16px' />
      <h3 className={styles.subtitle}>紹介ページ編集</h3>
      <Spacer size='8px' />
      <p className={styles.description}>
        ここでは紹介ページ（LP）の見え方を管理します。画像を設定することもできます。
      </p>
      <Spacer size='24px' />

      <Card title='紹介ページ編集' forAmbassador={true}>
        <Spacer size='16px' />
        <nav className={styles.nav}>
          <ul>
            <li
              className={previewDeviceType === 'mobile' ? styles.active : ''}
              onClick={() => setPreviewDeviceType('mobile')}
            >
              モバイル
            </li>
            <li
              className={previewDeviceType === 'pc' ? styles.active : ''}
              onClick={() => setPreviewDeviceType('pc')}
            >
              PC
            </li>
          </ul>
        </nav>

        <Spacer size='16px' />

        <div className={styles.preview}>
          <nav className={styles.tab}>
            <ul className={styles.tab__items}>
              <li className={`${styles.tab__item} ${styles.tab__item__active}`}>
                <a href='/document/ambassador/'>アンバサダー</a>
              </li>
              <li className={styles.tab__item}>
                <a href='/document/guest'>ゲスト</a>
              </li>
            </ul>
          </nav>

          <Spacer size='24px' />

          <div className={styles.preview__container}>
            <div
              className={
                previewDeviceType === 'mobile'
                  ? styles.preview__mobile
                  : styles.preview__desktop
              }
            >
              <iframe srcDoc={html} className={styles.preview__iframe} />
            </div>
          </div>
        </div>

        <Spacer size='24px' />

        <p className={styles.note}>
          ※最下部にアンバサダー参加用のリンクが表示されます。
        </p>
      </Card>

      <Spacer size='32px' />

      <Card
        title='編集'
        id='edit'
        className={`${styles.edit} ${isEdit ? styles.edit__active : ''}`}
      >
        <Spacer size='16px' />
        {false && (
          <>
            <nav className={styles.nav}>
              <ul>
                <li
                  className={editDeviceType === 'mobile' ? styles.active : ''}
                  onClick={() => setEditDeviceType('mobile')}
                >
                  モバイル
                </li>
                <li
                  className={editDeviceType === 'pc' ? styles.active : ''}
                  onClick={() => setEditDeviceType('pc')}
                >
                  PC
                </li>
              </ul>
            </nav>
            <Spacer size='16px' />
          </>
        )}

        <Button
          size='xs'
          variant='outline'
          colorScheme='blue'
          rightIcon={<ChevronUpIcon />}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          現在の内容を確認する
        </Button>

        <Spacer size='16px' />
        <ValidationErrors errors={errors} />

        <Stack direction='row' spacing='32px' width='100%' align='start'>
          <div className={styles.edit__preview}>
            <div
              className={
                previewDeviceType === 'mobile'
                  ? styles.edit__preview__mobile
                  : styles.edit__preview__desktop
              }
            >
              <iframe srcDoc={html} className={styles.edit__preview__iframe} />
            </div>
          </div>
          <div className={styles.edit__form}>
            <form>
              <label className={styles.edit__form__label}>ロゴイメージ</label>
              <Spacer size='8px' />
              <ImageLibrary parentImagePath={logoImagePath} setParentImagePath={setLogoImagePath} />
              <Spacer size='32px' />

              <label className={styles.edit__form__label}>メインビジュアル</label>
              <Spacer size='8px' />
              <ImageLibrary parentImagePath={mainImagePath} setParentImagePath={setMainImagePath} />
              <Spacer size='32px' />

              <label className={styles.edit__form__label}>タイトル</label>
              <Spacer size='8px' />
              <Input
                size='md'
                variant='outline'
                placeholder='友人を紹介してクーポンを手に入れよう！'
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  changeHtml('title', e.target.value);
                }}
              />
              <Spacer size='32px' />

              <label className={styles.edit__form__label}>サブタイトル</label>
              <Spacer size='8px' />
              <Input
                size='md'
                variant='outline'
                placeholder='友人を紹介してクーポンを手に入れよう！'
                value={subTitle}
                onChange={(e) => {
                  setSubTitle(e.target.value);
                  changeHtml('subtitle', e.target.value);
                }}
              />
              <Spacer size='32px' />

              <Stack
                direction='row'
                spacing='8px'
                align='center'
                justify='start'
              >
                <label className={styles.edit__form__label}>説明文</label>
                <HelpText label='テキストを入力してください' />
              </Stack>
              <Spacer size='8px' />
              <CustomEditor
                value={description}
                onChange={(v) => {
                  setDescription(v);
                  changeHtml('description', v);
                }}
              />
              <Spacer size='32px' />

              <Stack
                direction='row'
                spacing='8px'
                align='center'
                justify='start'
              >
                <label className={styles.edit__form__label}>参加方法</label>
                <HelpText label='テキストを入力してください' />
              </Stack>
              <Spacer size='8px' />
              <CustomEditor
                value={joinHelp}
                onChange={(v) => {
                  setJoinHelp(v);
                  changeHtml('joinhelp', v);
                }}
              />
              <Spacer size='32px' />

              <p className={styles.edit__form__label}>クーポン</p>
              <Spacer size='8px' />
              <div className={styles.edit__form__coupon}>
                <label className={styles.edit__form__coupon_label}>
                  アンバサダーインセンティブタイトル
                </label>
                <Spacer size='8px' />
                <Input
                  size='md'
                  variant='outline'
                  placeholder='PRESENT FOR YOU'
                  value={coupon.amb_incentive_title}
                  onChange={(e) => {
                    setCoupon({
                      ...coupon,
                      ...{ amb_incentive_title: e.target.value },
                    });
                    changeHtml('amb_incentive_title', e.target.value);
                  }}
                />
                <Spacer size='24px' />

                <label className={styles.edit__form__coupon_label}>
                  アンバサダーインセンティブ
                </label>
                <Spacer size='8px' />
                <Input
                  size='md'
                  variant='outline'
                  placeholder='クーポン  20%'
                  value={coupon.amb_incentive}
                  onChange={(e) => {
                    setCoupon({
                      ...coupon,
                      ...{ amb_incentive: e.target.value },
                    });
                    changeHtml('amb_incentive', e.target.value);
                  }}
                />
                <Spacer size='24px' />

                <label className={styles.edit__form__coupon_label}>
                  アンバサダーインセンティブ背景色
                </label>
                <Spacer size='8px' />
                <div
                  className={styles.edit__form__color_picker}
                  onClick={() =>
                    setDisplayAmbassadorColorPicker(
                      !displayAmbassadorColorPicker
                    )
                  }
                >
                  <div
                    className={styles.edit__form__color_picker__color}
                    style={{
                      background: `${coupon.amb_incentive_color}`,
                    }}
                  />
                  {displayAmbassadorColorPicker && (
                    <div className={styles.edit__form__color_picker__popover}>
                      <div
                        className={styles.edit__form__color_picker__cover}
                        onClick={() => setDisplayAmbassadorColorPicker(false)}
                      />
                      <Input
                        size='md'
                        variant='outline'
                        placeholder='クーポン  20%'
                        value={coupon.amb_incentive}
                        onChange={(e) =>
                          setCoupon({
                            ...coupon,
                            ...{ amb_incentive: e.target.value },
                          })
                        }
                      />
                      <SketchPicker
                        color={coupon.amb_incentive_color}
                        onChange={(color) => {
                          setCoupon({
                            ...coupon,
                            ...{ amb_incentive_color: color.hex },
                          });
                          changeHtml('amb_incentive_color', color.hex);
                        }}
                      />
                    </div>
                  )}
                </div>
                <Spacer size='24px' />

                <label className={styles.edit__form__coupon_label}>
                  ゲストインセンティブタイトル
                </label>
                <Spacer size='8px' />
                <Input
                  size='md'
                  variant='outline'
                  placeholder='PRESENT FOR YOUR FRIEND'
                  value={coupon.guest_incentive_title}
                  onChange={(e) => {
                    setCoupon({
                      ...coupon,
                      ...{ guest_incentive_title: e.target.value },
                    });
                    changeHtml('guest_incentive_title', e.target.value);
                  }}
                />
                <Spacer size='24px' />

                <label className={styles.edit__form__coupon_label}>
                  ゲストインセンティブ
                </label>
                <Spacer size='8px' />
                <Input
                  size='md'
                  variant='outline'
                  placeholder='15％OFFクーポン'
                  value={coupon.guest_incentive}
                  onChange={(e) => {
                    setCoupon({
                      ...coupon,
                      ...{ guest_incentive: e.target.value },
                    });
                    changeHtml('guest_incentive', e.target.value);
                  }}
                />
                <Spacer size='24px' />

                <label className={styles.edit__form__coupon_label}>
                  ゲストインセンティブ背景色
                </label>
                <Spacer size='8px' />
                <div
                  className={styles.edit__form__color_picker}
                  onClick={() =>
                    setDisplayGuestColorPicker(!displayGuestColorPicker)
                  }
                >
                  <div
                    className={styles.edit__form__color_picker__color}
                    style={{
                      background: `${coupon.guest_incentive_color}`,
                    }}
                  />
                  {displayGuestColorPicker && (
                    <div className={styles.edit__form__color_picker__popover}>
                      <div
                        className={styles.edit__form__color_picker__cover}
                        onClick={() => setDisplayGuestColorPicker(false)}
                      />
                      <SketchPicker
                        color={coupon.guest_incentive_color}
                        onChange={(color) => {
                          setCoupon({
                            ...coupon,
                            ...{ guest_incentive_color: color.hex },
                          });
                          changeHtml('guest_incentive_color', color.hex);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </Stack>
      </Card>

      <div className={styles.action}>
        {!isEdit && (
          <Button
            size='lg'
            variant='solid'
            colorScheme={'blue'}
            className={styles.action__edit}
            width='450px'
            height='48px'
            onClick={() => {
              setIsEdit(!isEdit);
              const target = document.getElementById('edit');
              const rect = target.getBoundingClientRect().top;
              const offset = window.pageYOffset;
              const gap = 80;
              const targetY = rect + offset - gap;
              window.scrollTo({ top: targetY, behavior: 'smooth' });
            }}
          >
            編集する
          </Button>
        )}
        {isEdit && (
          <Stack
            direction='row'
            spacing='8px'
            width='100%'
            align='center'
            justify='center'
          >
            <Button
              size='lg'
              variant='outline'
              colorScheme={'blue'}
              className={styles.action__cancel}
              width='240px'
              height='48px'
              onClick={() => setIsEdit(!isEdit)}
            >
              キャンセル
            </Button>
            <Button
              size='lg'
              variant='solid'
              colorScheme={'blue'}
              className={styles.action__save}
              width='240px'
              height='48px'
              onClick={save}
            >
              保存する
            </Button>
          </Stack>
        )}
      </div>
    </>
  );
};
