import { AmbassadorsScreen } from '@/frontend/components/pages/ambassadors/Ambassadors';

export const metadata = {
  title: 'アンバサダー一覧 | Letters',
  description: 'Lettersのアンバサダー一覧画面です。',
};

const Ambassadors = () => {
  return <AmbassadorsScreen />;
};

export default Ambassadors;
