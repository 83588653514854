import { UserScreen } from '@/frontend/components/pages/setting/User';

export const metadata = {
  title: 'ユーザー管理 | Letters',
  description: '',
};

const User = () => {
  return <UserScreen />;
};

export default User;
