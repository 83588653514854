import { useState, useEffect } from 'react';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { Card } from '@/frontend/components/elements/cards/Card';
import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Stack,
  Input,
  Select,
  useToast,
  Text,
} from '@chakra-ui/react';
import ValidationErrors from '@/frontend/components/elements/validation/ValidationErrors';

import styles from './User.module.scss';
import {
  getUserList as getUserListApi,
  postAddUser as postAddUserApi,
  postEditUser as postEditUserApi,
  deleteUser as deleteUserApi,
} from '@/frontend/src/backendApi'; // TODO:

// TODO: ログインユーザー権限での表示分け
export const UserScreen = (props) => {
  const [userList, setUserList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [editUser, setEditUser] = useState('');
  const [userInfo, setUserInfo] = useState({
    mail_address: '',
    name: '',
    role: 2,
    status: 1,
  });
  const [errors, setErrors] = useState({});

  const toast = useToast();

  // 登録ユーザー一覧情報取得
  const getUserList = async () => {
    const response = await getUserListApi();
    console.log('user_list');
    console.log(response.data.data);
    setUserList(response.data.data.user_list ?? []);
    setRoles(response.data.data.roles ?? []);
    setStatusList(response.data.data.status_list ?? []);
  };

  const setUpUserInfo = (type, userInfo = {}) => {
    switch (type) {
      case 'add':
        setEditUser('add');
        break;
      case 'edit':
        setUserInfo(userInfo);
        setEditUser('edit');
        break;
    }
  };

  const save = async () => {
    // TODO: フロントバリデーション
    const params = {
      user: {
        email: userInfo.mail_address,
        name: userInfo.name,
        role_id: userInfo.role,
        user_status_id: userInfo.status,
        password: userInfo.password ?? '',
      },
    };

    if (editUser === 'edit') {
      params['id'] = userInfo.id ?? null;
    }

    try {
      const response =
        editUser === 'add'
          ? await postAddUserApi(params)
          : await postEditUserApi(params);
      console.log(response);
      setErrors({});
    } catch (e) {
      console.error(e);
      setErrors(e.response.data.error ?? {});

      toast({
        title: '保存に失敗しました',
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }

    // 後処理
    window.scrollTo({ top: 0, behavior: 'smooth' });
    toast({
      title: '保存しました',
      status: 'success',
      duration: '3000',
      isClosable: true,
    });
    setEditUser('');
    setUserInfo({
      mail_address: '',
      name: '',
      role: '',
      status: '',
    });
    (async () => await getUserList())();
  };

  const deleteUser = async (id) => {
    if (
      !window.confirm('ユーザーを削除します。元に戻せませんがよろしいですか？')
    ) {
      return;
    }

    const params = { id: id };

    try {
      const response = await deleteUserApi(params);
      console.log(response);
    } catch (e) {
      console.error(e);
      toast({
        title: '削除に失敗しました',
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }

    // 後処理
    window.scrollTo({ top: 0, behavior: 'smooth' });
    toast({
      title: '削除しました',
      status: 'success',
      duration: '3000',
      isClosable: true,
    });
    setEditUser('');
    setUserInfo({
      mail_address: '',
      name: '',
      role: '',
      status: '',
    });
    (async () => await getUserList())();
  };

  // fetch
  useEffect(() => {
    getUserList();
  }, []);

  return (
    <>
      <h2 className={styles.title}>設定</h2>
      <Spacer size='24px' />
      <h3 className={styles.subtitle}>ユーザー管理</h3>
      <Spacer size='8px' />
      <p className={styles.description}>
        ここではログインユーザーの編集ができます。
      </p>
      <Spacer size='24px' />

      <Card>
        <nav className={styles.tab}>
          <ul className={styles.tab__items}>
            {props.role != 3 && (
              // 一般非表示
              <li className={styles.tab__item}>
                <a href='/setting/account/'>アカウント情報管理</a>
              </li>
            )}
            <li className={`${styles.tab__item} ${styles.tab__item__active}`}>
              <a href='/setting/user'>ユーザー管理</a>
            </li>
            <li className={styles.tab__item}>
              <a href='/setting/test_user'>テストユーザー管理</a>
            </li>
          </ul>
        </nav>

        <Spacer size='24px' />

        <div className={styles.section}>
          <h4 className={styles.section__title}>ユーザー情報</h4>
          <Spacer size='16px' />

          {editUser !== '' && (
            <>
              <ValidationErrors errors={errors} />
              <div className={styles.section__item}>
                <label name={'mail_address'}>メールアドレス</label>
                <Spacer size='8px' />
                <Input
                  name={'mail_address'}
                  placeholder={''}
                  htmlSize={10}
                  width={700}
                  value={userInfo.mail_address ?? ''}
                  onChange={(e) =>
                    setUserInfo({
                      ...userInfo,
                      ...{ mail_address: e.target.value },
                    })
                  }
                />
                <Spacer size='16px' />

                <label name={'password'}>パスワード</label>
                <Spacer size='8px' />
                <Input
                  name={'password'}
                  placeholder={''}
                  pr='4.5rem'
                  htmlSize={10}
                  width={700}
                  value={userInfo.password ?? ''}
                  type='password'
                  onChange={(e) =>
                    setUserInfo({
                      ...userInfo,
                      ...{ password: e.target.value },
                    })
                  }
                />
                {editUser === 'edit' && (
                  <>
                    <br />
                    <Text as='sub' color='tomato'>
                      変更する場合のみ入力してください
                    </Text>
                  </>
                )}
                <Spacer size='16px' />

                <label name={'name'}>氏名</label>
                <Spacer size='8px' />
                <Input
                  name={'name'}
                  placeholder={''}
                  htmlSize={10}
                  width={700}
                  value={userInfo.name ?? ''}
                  onChange={(e) =>
                    setUserInfo({
                      ...userInfo,
                      ...{ name: e.target.value },
                    })
                  }
                />
                <Spacer size='16px' />

                <label name={'authority'}>権限</label>
                <Spacer size='8px' />
                <Select
                  size='md'
                  defaultChecked={2}
                  value={userInfo.role ?? 2}
                  onChange={(e) =>
                    setUserInfo({
                      ...userInfo,
                      ...{ role: e.target.value },
                    })
                  }
                  width={200}
                >
                  {roles.map((v) => {
                    return (
                      <option key={v.id} value={v.id}>
                        {v.label}
                      </option>
                    );
                  })}
                </Select>

                <label name={'state'}>状態</label>
                <Spacer size='8px' />
                <Select
                  size='md'
                  defaultChecked={1}
                  value={userInfo.status ?? 1}
                  onChange={(e) =>
                    setUserInfo({
                      ...userInfo,
                      ...{ status: e.target.value },
                    })
                  }
                  width={200}
                >
                  {statusList.map((v) => {
                    return (
                      <option key={v.id} value={v.id}>
                        {v.label}
                      </option>
                    );
                  })}
                </Select>
              </div>
              <Spacer size='24px' />
            </>
          )}

          {editUser !== '' && (
            <>
              <Stack
                direction='row'
                spacing='8px'
                width='100%'
                align='center'
                justify='center'
              >
                <Button
                  size='lg'
                  variant='outline'
                  colorScheme={'blue'}
                  className={styles.action__cancel}
                  width='240px'
                  height='48px'
                  onClick={() => {
                    setEditUser('');
                  }}
                >
                  キャンセル
                </Button>
                <Button
                  colorScheme='green'
                  size={'lg'}
                  width={240}
                  className={styles.button}
                  onClick={save}
                >
                  保存する
                </Button>
              </Stack>
              <Spacer size='48px' />
            </>
          )}

          {editUser === '' && (
            <>
              <Button
                size={'md'}
                variant={'solid'}
                colorScheme={'blue'}
                onClick={() => {
                  setUpUserInfo('add');
                }}
              >
                新規追加
              </Button>
            </>
          )}

          <Spacer size='16px' />

          <div className={styles.table}>
            <TableContainer maxWidth={'100%'}>
              <Table>
                <Thead>
                  <Tr>
                    <Th>メールアドレス</Th>
                    <Th>氏名</Th>
                    <Th width={140}>権限</Th>
                    <Th width={140}>状態</Th>
                    <Th width={100}>操作</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {userList.map((user) => {
                    return (
                      <Tr key={user.id}>
                        <Td>{user.email}</Td>
                        <Td>{user.name}</Td>
                        <Td>{user.role_label}</Td>
                        <Td>{user.status_label}</Td>
                        <Td>
                          <Stack direction={'row'} spacing={'4px'}>
                            <Button
                              size={'xs'}
                              variant={'solid'}
                              colorScheme={'blue'}
                              onClick={() => {
                                setUpUserInfo('edit', {
                                  id: user.id,
                                  mail_address: user.email,
                                  name: user.name,
                                  role: user.role_id,
                                  status: user.user_status_id,
                                });
                              }}
                            >
                              編集
                            </Button>
                            <Button
                              size={'xs'}
                              variant={'outline'}
                              colorScheme={'red'}
                              onClick={() => {
                                deleteUser(user.id);
                              }}
                            >
                              削除
                            </Button>
                          </Stack>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Card>
    </>
  );
};
