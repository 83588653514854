'use client';

import { BannerItem } from './BannerItem';
import styles from './BannerSection.module.scss';
import guideBanner from '/home/banner-guide.png';
import inviteBanner from '/home/banner-invite.png';
import caseStudyBanner from '/home/banner-case-study.png';

export const BannerSection = () => {
  return (
    <div className={styles.container}>
      <BannerItem
        link='https://educated-guppy-e31.notion.site/Letters-16f260ec0281497db472c791652a3127'
        image={guideBanner}
        title='ご利用ガイド'
      />
      <BannerItem
        link='https://educated-guppy-e31.notion.site/Letters-16f260ec0281497db472c791652a3127#:~:text=%F0%9F%A4%9D%F0%9F%8F%BB-,%E3%81%8A%E5%8F%8B%E9%81%94%E7%B4%B9%E4%BB%8B%E3%81%AE%E3%82%B3%E3%83%84%20%E3%80%9C5%E3%81%A4%E3%81%AE%E3%83%AB%E3%83%BC%E3%83%AB%E3%80%9C,-%E3%81%8A%E5%8F%8B%E9%81%94%E7%B4%B9%E4%BB%8B'
        image={inviteBanner}
        title='お友達紹介のコツ'
      />
      <BannerItem
        link='https://note.com/lettersnote/n/nbb116a4c1789'
        image={caseStudyBanner}
        title='売り上げを伸ばす活用事例'
      />
    </div>
  );
};
