'use client';

import { useState, useEffect, useCallback } from 'react';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { HelpText } from '@/frontend/components/elements/helpText/HelpText';
import { NextActionStepItem } from '@/frontend/components/feature/Home/NextActionStepItem';
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { ChevronRightIcon } from "@chakra-ui/icons";
import ValidationErrors from '@/frontend/components/elements/validation/ValidationErrors';
import { Card } from '@/frontend/components/elements/cards/Card';
import {
  Badge,
  Radio,
  RadioGroup,
  Input,
  Button,
  Stack,
  Select,
  useToast,
} from '@chakra-ui/react';
import Icon from '@mdi/react';
import { mdiThumbUpOutline } from '@mdi/js';

import styles from "./Step3.module.scss";
import {
  getGuestIncentive as getGuestIncentiveApi,
  postGuestIncentive as postGuestIncentiveApi,
} from '@/frontend/src/backendApi';

export const Step3 = (props) => {
  const [type, setType] = useState('coupon');
  const [couponType, setCouponType] = useState('ratio');
  const [incentive, setIncentive] = useState({});

  // input
  const [inputMinPrice, setInputMinPrice] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [inputCouponCode, setInputCouponCode] = useState('');
  const [errors, setErrors] = useState({});

  const toast = useToast();

  // ゲストインセンティブ情報取得
  const getGuestIncentive = async () => {
    const response = await getGuestIncentiveApi();

    if ('coupon' in response.data.data.incentive) {
      setType('coupon');
      setCouponType(
        response.data.data.incentive.coupon.origin_coupon_type == 1
          ? 'ratio'
          : 'fixed'
      );
    } else {
      setType('none');
    }
    setIncentive(response.data.data.incentive ?? {});
  };

  const setInputDefault = useCallback(() => {
    if (type === 'coupon') {
      setInputMinPrice(
        incentive.coupon ? incentive.coupon.origin_min_price : ''
      );
      setInputValue(incentive.coupon ? incentive.coupon.origin_value : '');
      setInputCouponCode(
        incentive.coupon ? incentive.coupon.origin_coupon_code : ''
      );
    } else {
      setInputValue('');
    }
  }, [incentive, type]);

  const save = async () => {
    // TODO: フロントバリデーション
    const params = {
      type: type,
      couponType: couponType,
      min_price: inputMinPrice,
      value: inputValue,
      coupon_code: inputCouponCode,
    };
    try {
      const response = await postGuestIncentiveApi(params);
      console.log(response);
      setErrors({});
    } catch (e) {
      console.error(e);
      setErrors(e.response.data.error ?? {});

      toast({
        title: '保存に失敗しました',
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }

    // 後処理
    (async () => {
      await getGuestIncentive();
    })();
  };

  // fetch
  useEffect(() => {
    getGuestIncentive();
  }, []);

  // set input
  useEffect(() => {
    setInputDefault();
  }, [setInputDefault]);

  return (
    <>
      <div className={styles.container}>
        <ul className={styles.step}>
          <NextActionStepItem
            step='1'
            title='インセンティブ設定'
            description='for アンバサダー'
            status='done'
          />
          <NextActionStepItem
            step='2'
            title='インセンティブ設定'
            description='for ゲスト'
            status='active'
          />
          <NextActionStepItem
            step='3'
            title='運用スタート'
            description=''
            label=''
            status=''
          />
        </ul>

        <Spacer size='32px' />

        <p className={styles.title}>アンバサダーの紹介を受けて商品をご購入された方へのインセンティブを設定できます</p>

        <Spacer size='32px' />

        <Card>
          <div className={styles.edit}>
            <div className={styles.edit__type}>
              <RadioGroup onChange={setType} value={type}>
                <Stack
                  direction='row'
                  gap='80px'
                  className={styles.edit__type__stack}
                >
                  <Radio value='coupon'>
                    <Badge
                      colorScheme='green'
                      className={styles.edit__type__recommend}
                    >
                      おすすめ！
                    </Badge>
                    クーポン
                  </Radio>
                  <Radio value='none'>なし</Radio>
                </Stack>
              </RadioGroup>
              {type !== 'none' && (
                <>
                  <Spacer size='18px' />
                  <div className={styles.edit__type__description}>
                    <p>
                      自社ECサイトでご使用いただけるクーポンをインセンティブとして設定できます
                    </p>
                  </div>
                </>
              )}
            </div>

            <Spacer size='24px' />
            <ValidationErrors errors={errors} />

            {type === 'coupon' && (
              <>
                <div className={styles.edit__amount}>
                  <div className={styles.edit__title}>
                    1. インセンティブ内容
                    <Badge colorScheme='red'>必須</Badge>
                  </div>
                  <Spacer size='8px' />
                  <Stack direction={'row'} spacing={'16px'} align='start'>
                    <Select
                      size='md'
                      defaultChecked={couponType}
                      onChange={setCouponType}
                      width={140}
                    >
                      <option value='ratio'>割合</option>
                      <option value='fixed'>定額</option>
                    </Select>
                    <Stack direction={'row'} spacing={'8px'} align='center'>
                      <Input
                        placeholder='10'
                        htmlSize={10}
                        width={180}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                      />
                      <span>{couponType === 'ratio' ? '％' : '円'}</span>
                    </Stack>
                  </Stack>
                  <Spacer size='8px' />
                  <Stack
                    direction={'row'}
                    spacing={'8px'}
                    align='center'
                    className={styles.edit__advice}
                  >
                    <Icon
                      path={mdiThumbUpOutline}
                      size={'16px'}
                      color='rgba(128, 90, 213, 1)'
                    />
                    <span>
                      設定アドバイス：分かりやすくお得感を出すために1,000円OFFや10%OFFなどの設定をおすすめします。
                    </span>
                  </Stack>
                </div>

                <Spacer size='40px' />

                <div className={styles.edit__min_amount}>
                  <div className={styles.edit__title}>
                    2. クーポンコード
                    <Badge colorScheme='red'>必須</Badge>
                  </div>
                  <Spacer size='8px' />
                  <Input
                    placeholder='0'
                    htmlSize={10}
                    width={300}
                    value={inputCouponCode}
                    onChange={(e) => setInputCouponCode(e.target.value)}
                  />
                </div>

                <Spacer size='40px' />

                <div className={styles.edit__period}>
                  <div className={styles.edit__title}>
                    3. インセンティブ利用最小金額 (税込)
                    <Badge colorScheme='red'>必須</Badge>
                    <HelpText label='ここに説明文' />
                  </div>
                  <Spacer size='8px' />
                  <Stack direction={'row'} spacing={'8px'} align='center'>
                    <Input
                      placeholder='0'
                      htmlSize={10}
                      width={180}
                      value={inputMinPrice}
                      onChange={(e) => setInputMinPrice(e.target.value)}
                    />
                    <span>円</span>
                  </Stack>
                </div>
                <Spacer size='40px' />
              </>
            )}
          </div>
        </Card>

        <Spacer size='48px' />

        <Stack
          direction={'row'}
          spacing={'10px'}
          align='center'
          justify='center'
        >
          <Button
            size="lg"
            variant="outline"
            colorScheme="blue"
            leftIcon={<ChevronLeftIcon />}
            width={220}
            onClick={
              () => {
                props.onBack();
              }
            }
          >
            前の画面に戻る
          </Button>
          <Button
            size="lg"
            variant="solid"
            colorScheme="blue"
            rightIcon={<ChevronRightIcon />}
            width={220}
            onClick={
              async () => {
                await save();
                props.onNext();
              }
            }
          >
            保存して確認画面へ
          </Button>
        </Stack>

        <Spacer size='32px' />

        <a
          href='#'
          className={styles.textLink}
          onClick={
            async () => {
              await save();
              props.onSkip();
            }
          }
        >
          保存してホームに戻る
        </a>
      </div>
    </>
  );
};