import { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { Card } from '@/frontend/components/elements/cards/Card';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  HStack,
  VStack,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  useToast,
} from '@chakra-ui/react';
import ValidationErrors from '@/frontend/components/elements/validation/ValidationErrors';

import styles from './Order.module.scss';
import {
  getOrder as getOrderApi,
  getOrderDownload as getOrderDownloadApi,
} from '@/frontend/src/backendApi';

export const OrderScreen = () => {
  const toast = useToast();
  const [orderList, setOrderList] = useState([]);
  const [ambassadors, setAmbassadors] = useState({});
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(false);
  const [form, setForm] = useState({
    order_id: '',
    order_number: '',
    order_date_from: '',
    order_date_to: '',
    guest_email: '',
    ambassador_email: '',
  });
  const [errors, setErrors] = useState({});

  // 注文一覧取得
  const getOrder = async () => {
    const params = {
      page: page,
      form: form,
    };
    const response = await getOrderApi(params);
    console.log('get_order');
    console.log(response.data.data);

    setOrderList(
      response.data.data.order_list ? response.data.data.order_list.data : []
    );
    setNext(
      response.data.data.order_list ? response.data.data.order_list.next : false
    );
    setAmbassadors(response.data.data.ambassadors ?? {});
  };

  // 日付形式変換
  function formatDatetime(date, sep = '/') {
    const yyyy = date.getFullYear();
    const mm = ('00' + (date.getMonth() + 1)).slice(-2);
    const dd = ('00' + date.getDate()).slice(-2);
    const h = ('00' + date.getHours()).slice(-2);
    const m = ('00' + date.getMinutes()).slice(-2);

    return `${yyyy}${sep}${mm}${sep}${dd} ${h}:${m}`;
  }

  const search = async (e) => {
    e.preventDefault();

    const params = {
      form: form,
      page: 1, // TODO:
    };

    try {
      const response = await getOrderApi(params);
      console.log(response);
      setErrors({});
      setOrderList(
        response.data.data.order_list ? response.data.data.order_list.data : []
      );
      setNext(
        response.data.data.order_list
          ? response.data.data.order_list.next
          : false
      );
      setAmbassadors(response.data.data.ambassadors ?? {});
    } catch (e) {
      console.error(e);
      setErrors(e.response.data.error ?? {});

      toast({
        title: '検索に失敗しました',
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }
  };

  const download = async (e) => {
    e.preventDefault();

    const params = {
      form: form,
    };

    try {
      const response = await getOrderDownloadApi(params);
      console.log(response);
      setErrors({});
      const blob = new Blob([response.data], { type: 'text/csv' });

      // sv-SEロケールはYYYY-MM-DD形式の日付文字列を戻す
      saveAs(blob, `注文一覧_${new Date().toLocaleDateString('sv-SE')}.csv`);
    } catch (e) {
      console.error(e);
      setErrors({ range: ['100日以内で検索してください'] });

      toast({
        title: 'CSVダウンロードに失敗しました',
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }
  };

  // fetch
  useEffect(() => {
    getOrder();
  }, []);

  return (
    <>
      <h2 className={styles.title}>アンバサダー管理</h2>
      <Spacer size='16px' />
      <h3 className={styles.subtitle}>売り上げ注文一覧</h3>
      <Spacer size='8px' />
      <p className={styles.description}>
        ここではLetters経由の売り上げ注文一覧を確認できます。
      </p>
      <Spacer size='24px' />
      <ValidationErrors errors={errors} />

      <Card>
        <form>
          <VStack spacing='16px'>
            <HStack spacing='24px' width={'100%'}>
              <FormControl flex={1}>
                <FormLabel fontSize={14}>注文ID</FormLabel>
                <Input
                  type='text'
                  value={form.order_id}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      ...{ order_id: e.target.value },
                    });
                  }}
                />
              </FormControl>
              <FormControl flex={1}>
                <FormLabel fontSize={14}>注文番号</FormLabel>
                <Input
                  type='text'
                  value={form.order_id}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      ...{ order_id: e.target.value },
                    });
                  }}
                />
              </FormControl>
              <FormControl width={'fit-content'}>
                <FormLabel fontSize={14}>表示対象期間（最大100日）</FormLabel>
                <HStack spacing='8px'>
                  <InputGroup size='md'>
                    <Input
                      type='date'
                      placeholder='2024/01/01'
                      name='start_date'
                      className={styles.input}
                      minWidth={180}
                      value={form.order_date_from}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          ...{ order_date_from: e.target.value },
                        });
                      }}
                    />
                  </InputGroup>
                  <span>〜</span>
                  <InputGroup size='md'>
                    <Input
                      type='date'
                      placeholder='2024/01/31'
                      name='end_date'
                      className={styles.input}
                      minWidth={180}
                      value={form.order_date_to}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          ...{ order_date_to: e.target.value },
                        });
                      }}
                    />
                  </InputGroup>
                </HStack>
              </FormControl>
            </HStack>
            <HStack spacing='24px' width={'100%'}>
              <FormControl flex={1}>
                <FormLabel fontSize={14}>購入者メールアドレス</FormLabel>
                <Input
                  type='text'
                  value={form.guest_email}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      ...{ guest_email: e.target.value },
                    });
                  }}
                />
              </FormControl>
              <FormControl flex={1}>
                <FormLabel fontSize={14}>アンバサダーメールアドレス</FormLabel>
                <Input
                  type='text'
                  value={form.ambassador_email}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      ...{ ambassador_email: e.target.value },
                    });
                  }}
                />
              </FormControl>
            </HStack>
          </VStack>
          <Spacer size='16px' />
          <Button
            type='submit'
            size={'md'}
            variant={'solid'}
            colorScheme={'blue'}
            onClick={search}
          >
            検索する
          </Button>
        </form>
      </Card>

      <Spacer size='32px' />

      <Card
        title='検索結果'
        action={
          <Button
            size='sm'
            variant='outline'
            colorScheme='blue'
            onClick={download}
          >
            CSV出力
          </Button>
        }
      >
        <div className={styles.table}>
          <TableContainer maxWidth={'100%'}>
            <Table>
              <Thead>
                <Tr>
                  <Th>注文日時</Th>
                  <Th>注文ID</Th>
                  <Th>注文番号</Th>
                  <Th>アンバサダーメールアドレス</Th>
                  <Th>購入者氏名</Th>
                </Tr>
              </Thead>
              <Tbody>
                {orderList.map((v) => {
                  return (
                    <Tr key={v.order_id}>
                      <Td>{formatDatetime(new Date(v.datetime))}</Td>
                      <Td>{v.order_id}</Td>
                      <Td>{v.order_number}</Td>
                      <Td>{ambassadors[v.ambassador_uuid] ?? ''}</Td>
                      <Td>{`${v.last_name} ${v.first_name}`}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      </Card>
    </>
  );
};
