'use client';

import { Button } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import iconReview from '/icons/icon-review.svg';

import styles from './Review.module.scss';

export const Review = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.content__icon}>
          <img src={iconReview} alt='レビュー' />
        </div>
        <div className={styles.content__text}>
          <div className={styles.content__text__title}>
            アプリのレビューをお願いしています！
          </div>
          <div className={styles.content__text__description}>
            お客様のご意見をぜひお聞かせください！今後の改善に役立てさせていただきます。
          </div>
        </div>
      </div>
      <div className={styles.button}>
        <Button
          colorScheme='blue'
          size='sm'
          rightIcon={<ExternalLinkIcon />}
          onClick={() => {
            window.open('https://apps.shopify.com/letters-1/reviews');
          }}
        >
          レビューを書く
        </Button>
      </div>
    </div>
  );
};
