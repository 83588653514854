import { AccountScreen } from '@/frontend/components/pages/setting/Account';

export const metadata = {
  title: 'アカウント設定 | Letters',
  description: 'Lettersのアカウント設定画面です。',
};

const Account = () => {
  return <AccountScreen />;
};

export default Account;
