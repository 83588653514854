import {
  useDisclosure,
  Stack,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import Icon from '@mdi/react';
import { mdiCloseThick } from '@mdi/js';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { useDropzone } from "react-dropzone";
import { useCallback, useEffect, useState } from "react";
import {
  getImages as getImagesApi,
  postImage as postImageApi,
  deleteImage as deleteImageApi,
} from '@/frontend/src/backendApi';
import styles from "./ImageLibrary.module.scss";

export const ImageLibrary = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onDrop = useCallback((uploadImage) => {
    const formData = new FormData();
    formData.append("image[image]", uploadImage[0]);
    postImageApi(formData)
      .then(() => {
        getImagesApi()
          .then((response) => {
            setImages(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const [images, setImages] = useState([]);
  const { parentImagePath, setParentImagePath } = props;
  const [selectedImagePath, setSelectedImagePath] = useState(parentImagePath);

  // 画像削除
  const deleteImage = async (imageId) => {
    // ダイアログ表示
    if (!window.confirm("画像を削除しますか？")) {
      return;
    }

    // 画像削除
    deleteImageApi(imageId).then(() => {
      getImagesApi().then((response) => {
        setImages(response.data);
      });
    });
  };

  // 画像選択
  const selectImage = async (path) => {
    setParentImagePath(path);
    onClose();
  };

  useEffect(() => {
    setSelectedImagePath(parentImagePath);
  }, [parentImagePath]);

  useEffect(() => {
    if (isOpen) {
      getImagesApi().then((response) => {
        setImages(response.data);
      });
    }
  }, [isOpen]);

  return (
    <>
      <Stack spacing={3} direction={"row"}>
        {selectedImagePath && (
          <div className={styles.selectedImage}>
            <Image src={selectedImagePath} />
          </div>
        )}
        <Button onClick={onOpen}>画像を選択</Button>
      </Stack>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>画像選択</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={10}>
            <div {...getRootProps()} className={styles.dropzone}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>画像をここにドロップ</p>
              ) : (
                <p>画像をドラッグ＆ドロップするか、クリックして選択してください</p>
              )}
            </div>
            <Spacer size={"16px"} />
            <div className={styles.imageList}>
              {images.map((image) => (
                <div key={image.id} className={styles.imageItem} onClick={() => selectImage(image.image.url)}>
                  <Image src={image.image.url} />
                  <div className={styles.deleteButton} onClick={() => deleteImage(image.id)}>
                    <Icon
                      path={mdiCloseThick}
                      size={'16px'}
                      color='rgba(255, 255, 255, 1)'
                    />
                  </div>
                </div>
              ))}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
