import { GuestScreen } from '@/frontend/components/pages/document/Guest';

export const metadata = {
  title: '紹介ページ編集（ゲスト向け） - ドキュメント管理 | Letters',
  description: 'ゲスト向けの紹介ページを管理できます。',
};

const Guest = () => {
  return <GuestScreen />;
};

export default Guest;
