import { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { Card } from '@/frontend/components/elements/cards/Card';
import { Editor } from '@/frontend/components/elements/editor/Editor';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  HStack,
  VStack,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  useToast,
  Badge,
  Select,
  Stack,
} from '@chakra-ui/react';
import ValidationErrors from '@/frontend/components/elements/validation/ValidationErrors';

import { MdMailOutline } from 'react-icons/md';
import styles from './Ambassadors.module.scss';
import {
  getAmbassadors as getAmbassadorsApi,
  getAmbassadorsDownload as getAmbassadorsDownloadApi,
  postTestSendAmbassadorsMail as postTestSendAmbassadorsMailApi,
  postSendAmbassadorsMail as postSendAmbassadorsMailApi,
} from '@/frontend/src/backendApi';

export const AmbassadorsScreen = () => {
  const toast = useToast();
  const [ambassadorList, setAmbassadorList] = useState([]);
  const [cvObject, setCvObject] = useState({});
  const [editMail, setEditMail] = useState(false);
  const [body, setBody] = useState('');
  const [title, setTitle] = useState('');
  const [mailType, setMailType] = useState(2); // TODO: 不要
  const [form, setForm] = useState({
    start_date: '',
    end_date: '',
    ambassador_email: '',
  });
  const [errors, setErrors] = useState({});

  // アンバサダー一覧取得
  const getAmbassadors = async () => {
    const response = await getAmbassadorsApi();
    console.log('get_ambassadors');
    console.log(response.data.data);
    setAmbassadorList(response.data.data.ambassadors ?? []);
    setCvObject(response.data.data.cv_list ?? {});

    setForm(
      response.data.data.form ?? {
        start_date: '',
        end_date: '',
        ambassador_email: '',
      }
    );
  };

  // 日付形式変換
  function formatDatetime(date, sep = '/') {
    const yyyy = date.getFullYear();
    const mm = ('00' + (date.getMonth() + 1)).slice(-2);
    const dd = ('00' + date.getDate()).slice(-2);
    const h = ('00' + date.getHours()).slice(-2);
    const m = ('00' + date.getMinutes()).slice(-2);

    return `${yyyy}${sep}${mm}${sep}${dd} ${h}:${m}`;
  }

  // 再登録日時表示判定
  const reRegisterTime = (v) => {
    if (Date(v.created_at) < Date(v.agreetime)) {
      return formatDatetime(new Date(v.agreetime));
    }
    return '';
  };

  // ゲストCV
  const getCv = (uuid) => {
    return cvObject[uuid].cv ? Number(cvObject[uuid].cv).toLocaleString() : 0;
  };

  // 売り上げ金額
  const getSaleAmount = (uuid) => {
    return cvObject[uuid].sale_amount
      ? Number(cvObject[uuid].sale_amount).toLocaleString()
      : 0;
  };

  const search = async (e) => {
    e.preventDefault();

    const params = {
      ambassador_email: form.ambassador_email,
      start_date: form.start_date,
      end_date: form.end_date,
    };

    try {
      const response = await getAmbassadorsApi(params);
      console.log(response);

      setErrors({});
      setAmbassadorList(response.data.data.ambassadors ?? []);
      setCvObject(response.data.data.cv_list ?? {});
    } catch (e) {
      console.error(e);
      setErrors(e.response.data.error ?? {});

      toast({
        title: '検索に失敗しました',
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }
  };

  const download = async (e) => {
    e.preventDefault();

    const params = {
      ambassador_email: form.ambassador_email,
      start_date: form.start_date,
      end_date: form.end_date,
    };

    try {
      const response = await getAmbassadorsDownloadApi(params);
      console.log(response);
      setErrors({});
      const blob = new Blob([response.data], { type: 'text/csv' });

      // sv-SEロケールはYYYY-MM-DD形式の日付文字列を戻す
      saveAs(
        blob,
        `アンバサダー一覧_${new Date().toLocaleDateString('sv-SE')}.csv`
      );
    } catch (e) {
      setErrors({ range: ['100日以内で検索してください'] });

      console.error(e);
      toast({
        title: 'CSVダウンロードに失敗しました',
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }
  };

  const sendTestMail = async () => {
    // TODO: フロントバリデーション
    const params = {
      title: title,
      body: body,
      mail_type: mailType,
    };

    try {
      const response = await postTestSendAmbassadorsMailApi(params);
      console.log(response);
    } catch (e) {
      console.error(e);
      toast({
        title: '送信に失敗しました',
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }

    // 後処理
    window.scrollTo({ top: 0, behavior: 'smooth' });
    toast({
      title: '送信しました',
      status: 'success',
      duration: '3000',
      isClosable: true,
    });
  };

  const sendMail = async () => {
    // TODO: フロントバリデーション
    const params = {
      title: title,
      body: body,
      mail_type: mailType,
    };

    try {
      const response = await postSendAmbassadorsMailApi(params);
      console.log(response);
    } catch (e) {
      console.error(e);
      toast({
        title: '送信に失敗しました',
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }

    // 後処理
    window.scrollTo({ top: 0, behavior: 'smooth' });
    toast({
      title: '送信しました',
      status: 'success',
      duration: '3000',
      isClosable: true,
    });
  };

  // fetch
  useEffect(() => {
    getAmbassadors();

    setBody('');
    setTitle('');
    setMailType(2);
  }, []);

  return (
    <>
      <h2 className={styles.title}>アンバサダー管理</h2>
      <Spacer size='16px' />
      <h3 className={styles.subtitle}>
        アンバサダー（紹介プログラム登録者）一覧
      </h3>
      <Spacer size='8px' />
      <p className={styles.description}>
        ここではアンバサダー一覧を確認でき、メール送信などができます。
      </p>
      <Spacer size='24px' />
      <ValidationErrors errors={errors} />

      <Card>
        <form>
          <VStack spacing='16px'>
            <HStack spacing='24px' width={'100%'}>
              <FormControl flex={1}>
                <FormLabel fontSize={14}>メールアドレス</FormLabel>
                <Input
                  type='text'
                  value={form.ambassador_email}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      ...{ ambassador_email: e.target.value },
                    });
                  }}
                />
              </FormControl>
              <FormControl width={'fit-content'}>
                <FormLabel fontSize={14}>
                  登録日時表示対象期間（最大100日）
                </FormLabel>
                <HStack spacing='8px'>
                  <InputGroup size='md'>
                    <Input
                      type='date'
                      placeholder='2024/01/01'
                      name='start_date'
                      className={styles.input}
                      minWidth={180}
                      value={form.start_date}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          ...{ start_date: e.target.value },
                        });
                      }}
                    />
                  </InputGroup>
                  <span>〜</span>
                  <InputGroup size='md'>
                    <Input
                      type='date'
                      placeholder='2024/01/31'
                      name='end_date'
                      className={styles.input}
                      minWidth={180}
                      value={form.end_date}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          ...{ end_date: e.target.value },
                        });
                      }}
                    />
                  </InputGroup>
                </HStack>
              </FormControl>
            </HStack>
          </VStack>
          <Spacer size='16px' />
          <Button
            type='submit'
            size={'md'}
            variant={'solid'}
            colorScheme={'blue'}
            onClick={search}
          >
            検索する
          </Button>
        </form>
      </Card>

      <Spacer size='32px' />

      <Card
        title='検索結果'
        action={
          <HStack spacing='16px'>
            <Button
              size='sm'
              variant='solid'
              colorScheme='blue'
              onClick={() => setEditMail(true)}
            >
              全アンバサダーへフリーメールを送信する
            </Button>
            <Button
              size='sm'
              variant='outline'
              colorScheme='blue'
              onClick={download}
            >
              CSV出力
            </Button>
          </HStack>
        }
      >
        {editMail && (
          <>
            <Card>
              <div className={styles.edit__form}>
                <Stack direction='row' align='right' justify='right'>
                  <Button
                    size='sm'
                    variant='outline'
                    colorScheme='blue'
                    leftIcon={<MdMailOutline />}
                    onClick={sendTestMail}
                  >
                    テストメールを自分に送信
                  </Button>
                </Stack>
                <form>
                  <label className={styles.edit__form__label}>
                    メールタイトル
                    <Badge
                      colorScheme='red'
                      marginLeft='8px'
                      style={{
                        background: 'rgba(229, 62, 62, 1)',
                        color: '#ffffff',
                      }}
                    >
                      必須
                    </Badge>
                  </label>
                  <Spacer size='8px' />
                  <Input
                    size='md'
                    variant='outline'
                    placeholder='メールタイトル'
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <Spacer size='32px' />

                  {false && (
                    <>
                      <label className={styles.edit__form__label}>
                        メールタイプ
                      </label>
                      <Spacer size='8px' />
                      <Select size='md' w='320px'>
                        <option value='text'>テキストメール</option>
                        <option value='html'>HTMLメール</option>
                      </Select>
                      <Spacer size='32px' />
                    </>
                  )}

                  <label className={styles.edit__form__label}>メール本文</label>
                  <Spacer size='8px' />
                  <Editor value={body} setValue={setBody} />
                  <Spacer size='32px' />

                  <Stack direction='row' align='center' justify='center'>
                    <Button
                      size='lg'
                      variant='outline'
                      colorScheme={'blue'}
                      className={styles.action__cancel}
                      width='240px'
                      height='48px'
                      onClick={() => setEditMail(false)}
                    >
                      キャンセル
                    </Button>
                    <Button
                      size='lg'
                      variant='solid'
                      colorScheme={'blue'}
                      className={styles.action__save}
                      width='240px'
                      height='48px'
                      onClick={sendMail}
                    >
                      全アンバサダーに送信する
                    </Button>
                  </Stack>
                </form>
              </div>
            </Card>

            <Spacer size='32px' />
          </>
        )}

        <div className={styles.table}>
          <TableContainer maxWidth={'100%'}>
            <Table>
              <Thead>
                <Tr>
                  <Th>メールアドレス</Th>
                  <Th>登録日時</Th>
                  <Th>再登録日時</Th>
                  <Th>ゲストCV数</Th>
                  <Th>売上金額</Th>
                  <Th width={'60px'}>操作</Th>
                </Tr>
              </Thead>
              <Tbody>
                {ambassadorList.map((v) => {
                  return (
                    <Tr key={v.id}>
                      <Td>{v.email}</Td>
                      <Td>{formatDatetime(new Date(v.created_at))}</Td>
                      <Td>{reRegisterTime(v)}</Td>
                      <Td>{getCv(v.uuid)}</Td>
                      <Td>{getSaleAmount(v.uuid)}</Td>
                      <Td>
                        <a href={`/ambassadors/${v.id}`}>
                          <Button
                            size={'xs'}
                            variant={'solid'}
                            colorScheme={'blue'}
                          >
                            詳細
                          </Button>
                        </a>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      </Card>
    </>
  );
};
