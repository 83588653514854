import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// アンバサダー/ゲストのdocument編集は特殊なためeditorを分ける
export const CustomEditor = (props) => {
  // 利用しないものをコメントアウト
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      // [{ script: 'sub' }, { script: 'super' }],
      [{ header: 1 }, { header: 2 }, 'blockquote', 'code-block'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      // [{ direction: 'rtl' }, { align: [] }],s
      // ['link', 'image', 'video', 'formula'],
      // ['clean'],
    ],
  };

  return (
    <ReactQuill
      theme='snow'
      modules={modules}
      value={props.value}
      onChange={props.onChange}
    />
  );
};
