'use client';

import { useState, useEffect } from 'react';
import { Button } from '@chakra-ui/react';
import { Card } from '@/frontend/components/elements/cards/Card';
import beginnerIcon from '/icons/icon-beginner.svg';
import { NextActionStepItem } from './NextActionStepItem';
import { ChevronRightIcon } from '@chakra-ui/icons';

import styles from './NextAction.module.scss';

// TODO: ステップごとにリンク先変更
export const NextAction = (props) => {
  const [ambLabel, setAmbLabel] = useState('未入力項目あり');
  const [guestLabel, setGuestLabel] = useState('未入力項目あり');
  const [ambStatus, setAmbStatus] = useState('');
  const [guestStatus, setGuestStatus] = useState('');
  const [programStatus, setProgramStatus] = useState('');
  const [stepStatus, setStepStatus] = useState('');

  useEffect(() => {
    if (!props.ambSettingProgress && !props.guestSettingProgress) {
      setAmbLabel('未入力項目あり');
      setAmbStatus('active');
      setGuestLabel('未入力項目あり');
      setGuestStatus('');
      setStepStatus('');
    } else if (props.ambSettingProgress && !props.guestSettingProgress) {
      setAmbLabel('完了');
      setAmbStatus('done');
      setGuestLabel('未入力項目あり');
      setGuestStatus('active');
      setStepStatus('3ステップ内1ステップが完了しました！');
    } else if (!props.ambSettingProgress && props.guestSettingProgress) {
      setAmbLabel('未入力項目あり');
      setAmbStatus('active');
      setGuestLabel('完了');
      setGuestStatus('done');
      setStepStatus('3ステップ内1ステップが完了しました！');
    } else if (props.ambSettingProgress && props.guestSettingProgress) {
      setAmbLabel('完了');
      setAmbStatus('done');
      setGuestLabel('完了');
      setGuestStatus('done');
      setStepStatus('');
      setProgramStatus('active');
    }
  }, [props]);

  return (
    <Card title='次にやることリスト'>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.header__left}>
            <div className={styles.header__title}>
              <img
                src={beginnerIcon}
                alt='かんたんスタート設定'
                className={styles.header__icon}
              />
              かんたんスタート設定
            </div>
            <div className={styles.header__progress}>{stepStatus}</div>
          </div>
          <div className={styles.header__right}>
            { programStatus !== 'active' && (
              <Button
                colorScheme='blue'
                size='sm'
                rightIcon={<ChevronRightIcon />}
                onClick={() => {
                  window.location.reload();
                }}
              >
                編集を再開する
              </Button>
            )}
          </div>
        </div>
        <div className={styles.content}>
          <ul className={styles.step}>
            <NextActionStepItem
              step='1'
              title='インセンティブ設定'
              description='for アンバサダー'
              label={ambLabel}
              status={ambStatus}
            />
            <NextActionStepItem
              step='2'
              title='インセンティブ設定'
              description='for ゲスト'
              label={guestLabel}
              status={guestStatus}
            />
            <NextActionStepItem
              step='3'
              title='運用スタート'
              description=''
              label=''
              status={programStatus}
            />
          </ul>
        </div>
      </div>
    </Card>
  );
};
