import axios from 'axios';

// CSRF token set
axios.interceptors.request.use(
  (config) => {
    if (['post', 'put', 'patch', 'delete'].includes(config.method)) {
      config.headers['X-CSRF-Token'] =
        document.getElementsByName('csrf-token')[0].content;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * try/catch sample
 * @returns
 */
export async function getTodo() {
  try {
    return await axios.get(`/api/v1/todo`);
  } catch (err) {
    // error handling
  }
}

/***************
 * COMMON
 ***************/
export async function getLoginInfo() {
  return await axios.get(`/api/v1/setting/login_info`);
}

/***************
 * HOME
 ***************/
export async function getHome() {
  return await axios.get(`/api/v1/home`);
}
export async function getHomeReport() {
  return await axios.post(`/api/v1/home/async_report`);
}
export async function postTestMode() {
  return await axios.post(`/api/v1/home/test_mode_onoff`);
}
export async function getIncentive() {
  return await axios.get(`/api/v1/home/get_incentive`);
}
export async function getProgramState() {
  return await axios.get(`/api/v1/home/get_program_onoff`);
}
export async function postProgramState(state) {
  return await axios.post(`/api/v1/home/program_onoff`, {
    onoff: state,
  });
}
export async function getSettingProgress() {
  return await axios.get(`/api/v1/home/get_setting_progress`);
}
export async function getAmbassadorRanking() {
  return await axios.get(`/api/v1/home/get_ambassador_ranking`);
}

/***************
 * SEtTING
 ***************/
export async function getSettingCompany() {
  return await axios.get(`/api/v1/setting/account/company`);
}
export async function getSettingBrandInfo() {
  return await axios.get(`/api/v1/setting/account/brand`);
}
export async function getSettingLoginInfo() {
  return await axios.get(`/api/v1/setting/account/login_user_info`);
}
export async function getUserList() {
  return await axios.get(`/api/v1/setting/account/user`);
}
export async function postAccountSetting(params) {
  return await axios.post(`/api/v1/setting/account/update_setting`, params);
}
export async function postAddUser(params) {
  return await axios.post(`/api/v1/setting/account/user`, params);
}
export async function postEditUser(params) {
  return await axios.post(`/api/v1/setting/account/user/edit`, params);
}
export async function deleteUser(params) {
  return await axios.post(`/api/v1/setting/account/user/delete`, params);
}
export async function getTestUserList() {
  return await axios.get(`/api/v1/setting/account/test_user`);
}
export async function postAddTestUser(params) {
  return await axios.post(`/api/v1/setting/account/test_user`, params);
}
export async function postEditTestUser(params) {
  return await axios.post(`/api/v1/setting/account/test_user/edit`, params);
}
export async function deleteTestUser(params) {
  return await axios.post(`/api/v1/setting/account/test_user/delete`, params);
}

/***************
 * INCENTIVE
 ***************/
export async function getAmbIncentive() {
  return await axios.get(`/api/v1/incentive/get_amb_incentive`);
}
export async function postAmbIncentive(params) {
  return await axios.post(`/api/v1/incentive/post_amb_incentive`, params);
}
export async function getGuestIncentive() {
  return await axios.get(`/api/v1/incentive/get_guest_incentive`);
}
export async function postGuestIncentive(params) {
  return await axios.post(`/api/v1/incentive/post_guest_incentive`, params);
}
export async function getCouponCode(params) {
  return await axios.get(`/api/v1/incentive/get_coupon_code`, {
    params: params,
  });
}
export async function postImportCouponCode(params) {
  return await axios.post(`/api/v1/incentive/post_import_incentive`, params);
}
export async function postImportCsv(params) {
  return await axios.post(
    `/api/v1/incentive/post_import_csv_incentive`,
    params,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
}
export async function deleteCouponCode(params) {
  return await axios.post(`/api/v1/incentive/post_delete_code`, params);
}

/***************
 * REPORT
 ***************/
export async function getReport(params) {
  return await axios.get(`/api/v1/report`, {
    params: params,
  });
}
export async function getReportDownload(params) {
  return await axios.get(`/api/v1/report/download`, {
    params: params,
    responseType: 'blob',
  });
}

/***************
 * AMBASSADORS
 ***************/
export async function getAmbassadors(params) {
  return await axios.get(`/api/v1/ambassadors`, {
    params: params,
  });
}
export async function getAmbassadorsDownload(params) {
  return await axios.get(`/api/v1/ambassadors/download`, {
    params: params,
    responseType: 'blob',
  });
}
export async function postTestSendAmbassadorsMail(params) {
  return await axios.post(`/api/v1/ambassadors/post_test_send_mail`, params);
}
export async function postSendAmbassadorsMail(params) {
  return await axios.post(`/api/v1/ambassadors/post_send_mail`, params);
}
export async function getAmbassador(id, params) {
  return await axios.get(`/api/v1/ambassadors/${id}`, {
    params: params,
  });
}
export async function getBlockList() {
  return await axios.get(`/api/v1/ambassador/block`);
}

/***************
 * ORDER
 ***************/
export async function getOrder(params) {
  return await axios.get(`/api/v1/order/get_order`, {
    params: params,
  });
}
export async function getOrderDownload(params) {
  return await axios.get(`/api/v1/order/download`, {
    params: params,
    responseType: 'blob',
  });
}

/***************
 * DOCUMENT
 ***************/
export async function getAmbDocument() {
  return await axios.get(`/api/v1/document/get_ambassador`);
}
export async function postAmbDocument(params) {
  return await axios.post(`/api/v1/document/post_ambassador`, params);
}
export async function getGuestDocument() {
  return await axios.get(`/api/v1/document/get_guest`);
}
export async function postGuestDocument(params) {
  return await axios.post(`/api/v1/document/post_guest`, params);
}
export async function getRecruitMailDocument() {
  return await axios.get(`/api/v1/document/get_recruit_mail`);
}
export async function getJoinMailDocument() {
  return await axios.get(`/api/v1/document/get_join_mail`);
}
export async function postUpdateRecruitMailSetting(params) {
  return await axios.post(`/api/v1/document/post_update_recruit_mail`, params);
}
export async function postTestSendRecruitMail(params) {
  return await axios.post(
    `/api/v1/document/post_test_send_recruit_mail`,
    params
  );
}
export async function postUpdateJoinMailSetting(params) {
  return await axios.post(`/api/v1/document/post_update_join_mail`, params);
}
export async function postTestSendJoinMail(params) {
  return await axios.post(`/api/v1/document/post_test_send_join_mail`, params);
}
export async function postGeneratePreviewHtml(params) {
  return await axios.post(`/api/v1/document/generate_preview_html`, params);
}
export async function postUpdateRecruitMailSendSetting(params) {
  return await axios.post(
    `/api/v1/document/post_update_recruit_mail_send_setting`,
    params
  );
}

/***************
 * IMAGE
 ***************/
export async function getImages() {
  return await axios.get(`/api/v1/image/images`);
}
export async function postImage(params) {
  return await axios.post(`/api/v1/image/images`, params);
}
export async function deleteImage(imageId) {
  return await axios.delete(`/api/v1/image/images/${imageId}`);
}

/***************
 * OTHER
 ***************/

/**
 * @returns
 */
export async function postConfirmPassword(password) {
  return await axios.post(`/password/confirm`, {
    password: password,
  });
}
