import { TestUserScreen } from '@/frontend/components/pages/setting/TestUser';

export const metadata = {
  title: 'テストユーザー管理 | Letters',
  description: '',
};

const TestUser = () => {
  return <TestUserScreen />;
};

export default TestUser;
