import { BlockScreen } from '@/frontend/components/pages/ambassador/Block';

export const metadata = {
  title: 'メールブロック一覧 | Letters',
  description: 'Lettersのメールブロック一覧画面です。',
};

const Block = () => {
  return <BlockScreen />;
};

export default Block;
