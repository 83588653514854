'use client';

import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { BannerSection } from '@/frontend/components/feature/Home/BannerSection';
import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Button
} from '@chakra-ui/react';

import styles from "./Step5.module.scss";
export const Step5 = (props) => {
  return (
    <>
      <div className={styles.container}>
        <p className={styles.title}>
          設定完了しました！<br />
          運用スタートです🎉
        </p>

        <Spacer size="22px" />

        <Button
          size="lg"
          variant="solid"
          colorScheme="blue"
          rightIcon={<ChevronRightIcon />}
          width={220}
          onClick={
            () => {
              props.onSkip();
            }
          }
        >
          ホームへ
        </Button>

        <Spacer size="72px" />

        <BannerSection />
      </div>
    </>
  );
};