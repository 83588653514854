import { AmbassadorScreen } from '@/frontend/components/pages/incentive/Ambassador';

export const metadata = {
  title: 'インセンティブ管理 - アンバサダー | Letters',
  description: 'アンバサダーのインセンティブを管理できます。',
};

const Ambassador = () => {
  return <AmbassadorScreen />;
};

export default Ambassador;
