'use client';

import { useState, useEffect, useCallback } from 'react';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { HelpText } from '@/frontend/components/elements/helpText/HelpText';
import ValidationErrors from '@/frontend/components/elements/validation/ValidationErrors';
import { Card } from '@/frontend/components/elements/cards/Card';
import {
  Badge,
  Radio,
  RadioGroup,
  Input,
  Button,
  Stack,
  Select,
  useToast,
} from '@chakra-ui/react';
import Icon from '@mdi/react';
import { mdiThumbUpOutline } from '@mdi/js';

import styles from './Guest.module.scss';
import {
  getGuestIncentive as getGuestIncentiveApi,
  postGuestIncentive as postGuestIncentiveApi,
} from '@/frontend/src/backendApi';

export const GuestScreen = () => {
  const [type, setType] = useState('coupon');
  const [couponType, setCouponType] = useState('ratio');
  const [incentive, setIncentive] = useState({});
  const [incentiveInfo, setIncentiveInfo] = useState({});

  // input
  const [inputMinPrice, setInputMinPrice] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [inputCouponCode, setInputCouponCode] = useState('');
  const [errors, setErrors] = useState({});

  const toast = useToast();

  // ゲストインセンティブ情報取得
  const getGuestIncentive = async () => {
    const response = await getGuestIncentiveApi();
    console.log('amb_incentive');
    console.log(response.data.data);

    if ('coupon' in response.data.data.incentive) {
      setIncentiveInfo(response.data.data.incentive.coupon);
      setType('coupon');
      setCouponType(
        response.data.data.incentive.coupon.origin_coupon_type == 1
          ? 'ratio'
          : 'fixed'
      );
    } else {
      setIncentiveInfo({});
      setType('none');
    }
    setIncentive(response.data.data.incentive ?? {});
  };

  const setInputDefault = useCallback(() => {
    if (type === 'coupon') {
      setInputMinPrice(
        incentive.coupon ? incentive.coupon.origin_min_price : ''
      );
      setInputValue(incentive.coupon ? incentive.coupon.origin_value : '');
      setInputCouponCode(
        incentive.coupon ? incentive.coupon.origin_coupon_code : ''
      );
    } else {
      setInputValue('');
    }
  }, [incentive, type]);

  const save = async () => {
    // TODO: フロントバリデーション
    const params = {
      type: type,
      couponType: couponType,
      min_price: inputMinPrice,
      value: inputValue,
      coupon_code: inputCouponCode,
    };
    try {
      const response = await postGuestIncentiveApi(params);
      console.log(response);
      setErrors({});
    } catch (e) {
      console.error(e);
      setErrors(e.response.data.error ?? {});

      toast({
        title: '保存に失敗しました',
        status: 'error',
        duration: '3000',
        isClosable: true,
      });
      return;
    }

    // 後処理
    (async () => {
      await getGuestIncentive();
      window.scrollTo({ top: 0, behavior: 'smooth' });
      toast({
        title: '保存しました',
        status: 'success',
        duration: '3000',
        isClosable: true,
      });
    })();
  };

  // fetch
  useEffect(() => {
    getGuestIncentive();
  }, []);

  // set input
  useEffect(() => {
    setInputDefault();
  }, [setInputDefault]);

  return (
    <>
      <h2 className={styles.title}>インセンティブ管理</h2>
      <p className={styles.description}>
        ここではゲストのインセンティブを管理できます。
      </p>

      <Spacer size='24px' />

      <Card title='インセンティブ情報' label='ゲスト' forGuest={true}>
        <div className={styles.info}>
          <table>
            <tbody>
              <tr>
                <th>インセンティブタイプ</th>
                <td>{incentiveInfo.type ?? '-'}</td>
              </tr>
              <tr>
                <th>インセンティブ内容</th>
                <td>{incentiveInfo.body ?? '-'}</td>
              </tr>
              <tr>
                <th>インセンティブ発生最小金額 (税込)</th>
                <td>{incentiveInfo.min_price ?? '-'}</td>
              </tr>
              <tr>
                <th>クーポンコード</th>
                <td>{incentiveInfo.coupon_code ?? '-'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>

      <Spacer size='32px' />

      <Card title='編集'>
        <div className={styles.edit}>
          <div className={styles.edit__type}>
            <RadioGroup onChange={setType} value={type}>
              <Stack
                direction='row'
                gap='80px'
                className={styles.edit__type__stack}
              >
                <Radio value='coupon'>
                  <Badge
                    colorScheme='green'
                    className={styles.edit__type__recommend}
                  >
                    おすすめ！
                  </Badge>
                  クーポン
                </Radio>
                <Radio value='none'>なし</Radio>
              </Stack>
            </RadioGroup>
            {type !== 'none' && (
              <>
                <Spacer size='18px' />
                <div className={styles.edit__type__description}>
                  <p>
                    自社ECサイトでご使用いただけるクーポンをインセンティブとして設定できます
                  </p>
                </div>
              </>
            )}
          </div>

          <Spacer size='24px' />
          <ValidationErrors errors={errors} />

          {type === 'coupon' && (
            <>
              <div className={styles.edit__amount}>
                <div className={styles.edit__title}>
                  1. インセンティブ内容
                  <Badge colorScheme='red'>必須</Badge>
                </div>
                <Spacer size='8px' />
                <Stack direction={'row'} spacing={'16px'} align='start'>
                  <Select
                    size='md'
                    defaultChecked={couponType}
                    onChange={setCouponType}
                    width={140}
                  >
                    <option value='ratio'>割合</option>
                    <option value='fixed'>定額</option>
                  </Select>
                  <Stack direction={'row'} spacing={'8px'} align='center'>
                    <Input
                      placeholder='10'
                      htmlSize={10}
                      width={180}
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                    <span>{couponType === 'ratio' ? '％' : '円'}</span>
                  </Stack>
                </Stack>
                <Spacer size='8px' />
                <Stack
                  direction={'row'}
                  spacing={'8px'}
                  align='center'
                  className={styles.edit__advice}
                >
                  <Icon
                    path={mdiThumbUpOutline}
                    size={'16px'}
                    color='rgba(128, 90, 213, 1)'
                  />
                  <span>
                    設定アドバイス：分かりやすくお得感を出すために1,000円OFFや10%OFFなどの設定をおすすめします。
                  </span>
                </Stack>
              </div>

              <Spacer size='40px' />

              <div className={styles.edit__min_amount}>
                <div className={styles.edit__title}>
                  2. クーポンコード
                  <Badge colorScheme='red'>必須</Badge>
                  <HelpText
                    label={
                      <div>
                        Shopify上で発行したディスカウントコードを入力してください。
                        <br />
                        ゲストが利用するクーポンコードは統一となります。
                      </div>
                    }
                  />
                </div>
                <Spacer size='8px' />
                <Input
                  placeholder='0'
                  htmlSize={10}
                  width={300}
                  value={inputCouponCode}
                  onChange={(e) => setInputCouponCode(e.target.value)}
                />
              </div>

              <Spacer size='40px' />

              <div className={styles.edit__period}>
                <div className={styles.edit__title}>
                  3. インセンティブ利用最小金額 (税込)
                  <Badge colorScheme='red'>必須</Badge>
                </div>
                <Spacer size='8px' />
                <Stack direction={'row'} spacing={'8px'} align='center'>
                  <Input
                    placeholder='0'
                    htmlSize={10}
                    width={180}
                    value={inputMinPrice}
                    onChange={(e) => setInputMinPrice(e.target.value)}
                  />
                  <span>円</span>
                </Stack>
              </div>
              <Spacer size='40px' />
            </>
          )}

          <div className={styles.edit__button}>
            <Stack
              direction={'row'}
              spacing={'10px'}
              align='center'
              justify='center'
            >
              <Button
                colorScheme='blue'
                variant='outline'
                size={'lg'}
                width={240}
                onClick={() => {
                  getGuestIncentive();
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              >
                キャンセル
              </Button>
              <Button
                colorScheme='green'
                size={'lg'}
                width={240}
                onClick={save}
              >
                保存する
              </Button>
            </Stack>
          </div>
        </div>
      </Card>
    </>
  );
};
