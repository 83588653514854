import { RecruitMailScreen } from '@/frontend/components/pages/document/RecruitMail';

export const metadata = {
  title: '招待メール設定 - ドキュメント管理 | Letters',
  description: 'ここではアンバサダーへの招待メールの見え方を設定します。',
};

const RecruitMail = () => {
  return <RecruitMailScreen />;
};

export default RecruitMail;
