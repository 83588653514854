import styles from "./MenuItem.module.scss";

export const MenuItem = (props) => {
  return (
    <a href={ props.url } className={`${styles.item} ${props.active && 'active'}`}>
      {props.icon && <img src={props.icon} alt={props.name} className={styles.icon} />}
      {props.name}
      {props.forAmbassador && <span className={styles.forAmbassador}>for アンバサダー</span>}
      {props.forGuest && <span className={styles.forGuest}>for ゲスト</span>}
      <svg className={styles.arrow} width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.99984 0L0.589844 1.41L5.16984 6L0.589844 10.59L1.99984 12L7.99984 6L1.99984 0Z"　/>
      </svg>
    </a>
  );
};
