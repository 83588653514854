'use client';

import styles from './CopyButton.module.scss';
import { Tooltip } from '@chakra-ui/react';
import { useState } from 'react';
import copyIcon from '/icons/icon-copy.svg';

export const CopyButton = (props) => {
  const [copied, setCopied] = useState(false);

  return (
    <Tooltip isOpen={copied} label='コピーしました' placement='auto' hasArrow>
      <button
        className={styles.button}
        onClick={() => {
          navigator.clipboard.writeText(props.url);
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        }}
      >
        {props.text}
        <span className={styles.button__icon}>
          <img src={copyIcon} alt='コピー' />
        </span>
      </button>
    </Tooltip>
  );
};
