'use client';

import { Card } from '@/frontend/components/elements/cards/Card';

import styles from './Incentive.module.scss';
import iconAmbassador from '/icons/icon-ambassador.svg';
import iconGuest from '/icons/icon-guest.svg';

export const Incentive = (props) => {
  return (
    <Card style={{ padding: 0 }}>
      <div className={styles.container}>
        <div className={styles.ambassador}>
          <div className={styles.ambassador__title}>
            <img
              src={iconAmbassador}
              alt='アンバサダー'
              className={styles.ambassador__icon}
            />
            アンバサダーインセンティブ
          </div>
          <div className={styles.ambassador__content}>{props.ambIncentive}</div>
          <a className={styles.ambassador__edit} href='/incentive/ambassador'>
            変更する
          </a>
        </div>
        <div className={styles.guest}>
          <div className={styles.guest__title}>
            <img src={iconGuest} alt='ゲスト' className={styles.guest__icon} />
            ゲストインセンティブ
          </div>
          <div className={styles.guest__content}>{props.guestIncentive}</div>
          <a className={styles.guest__edit} href='/incentive/guest'>
            変更する
          </a>
        </div>
      </div>
    </Card>
  );
};
