import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';

export const BlindModal = () => {
  console.log(11111111111);
  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={true}
        onClose={() => {
          false;
        }}
        isCentered
        size={'xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>新プランへ切り替えをお願いします</ModalHeader>
          <ModalBody pb={10}>
            2024年4月1日より「Light」「Standard」「Pro」プランの提供を開始しました。
            <br />
            各プランへの切り替えは「Shopify管理画面」より各店舗のご判断で実施していただく必要があります。
            <br />
            <br />
            現在、新料金プランへの切り替えが行われていないため、Lettersアプリの管理画面が使用できなくなりました。
            <br />
            <p>
              新プラン切り替えの詳細は
              <a
                href='https://educated-guppy-e31.notion.site/2024-4-64f056b953d249ada970eecb441950ba#816a765efd03473497f14105dfedcbb5'
                target='_blank'
                style={{ color: 'blue' }}
              >
                こちら
              </a>
              をご確認ください。
            </p>
            <br />
            なお、以下注意事項となりますのでご確認ください。
            <br />
            <UnorderedList>
              <ListItem>
                Lettersアプリの管理画面が使用できない状態ですが、紹介プログラムは最新の設定のまま引き続き継続されています。
              </ListItem>
              <ListItem>
                新プランへの切り替えが行われない場合でも、旧プラン月45$の定額料金は引き続き支払いが発生しますので予めご了承ください。
              </ListItem>
              <ListItem>
                上記細かい変更が発生する場合があります。その際は別途メールにて通知します。
              </ListItem>
            </UnorderedList>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
