"use client";

import { Badge } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

import styles from './NextActionStepItem.module.scss';

export const NextActionStepItem = (props) => {
  return (
    <>
      { props.status == "done" && (
        <li className={`${styles.container} ${styles.container__done}`}>
          <div className={`${styles.container__step} ${styles.container__step__done}`}>{ <CheckIcon /> }</div>
          <div className={styles.container_text}>
            <div className={styles.container__text__title}>{ props.title }</div>
            { props.description && <div className={styles.container__text__description}>{props.description}</div> }
            { props.label && <Badge colorScheme='green'>{ props.label }</Badge> }
          </div>
        </li>
      )}
      { props.status == "active" && (
        <li className={`${styles.container} ${styles.container__active}`}>
          <div className={`${styles.container__step} ${styles.container__step__active}`}>{ props.step }</div>
          <div className={styles.container_text}>
            <div className={styles.container__text__title}>{ props.title }</div>
            { props.description && <div className={styles.container__text__description}>{props.description}</div> }
            { props.label && <Badge colorScheme='red'>{ props.label }</Badge> }
          </div>
        </li>
      )}
      { props.status == "" && (
        <li className={styles.container}>
          <div className={styles.container__step}>{ props.step }</div>
          <div className={styles.container_text}>
            <div className={`${styles.container__text__title} ${styles.container__text__title__oneline}`}>{ props.title }</div>
            { props.description && <div className={styles.container__text__description}>{props.description}</div> }
            { props.label && <Badge colorScheme='red'>{ props.label }</Badge> }
          </div>
        </li>
      )}
    </>

  );
}